import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import { useFormik } from "formik";
import { Modal } from "@mui/material";

import api from "services/api";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import STATUS from "constants/status";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppInputDate from "components/app-input-date";
import AppInputDragAndDropFiles from "components/app-input-drag-and-drop-files";

import closeIcon from "assets/images/close-icon.png";

const AppAllClaimHistoryModal = (props, ref) => {
	const [visible, setVisible] = useState(false);
	const downloadingFile = useRef(false);

	const initialValues = useMemo(() => {
		const values = {
			id: "",
			date: "",
			name: "",
			employeeId: "",
			status: "",
			receiptAmount: "",
			datePaid: "",
			claimType: "",
			reviewedBy: "",
			submitDate: "",
			rejectedBy: "",
			description: "",
			receiptDate: "",
			reason: "",
			file: []
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues
	});

	//prettier-ignore
	const onHandleShow = useCallback((obj) => {
		formik.setValues({
			id: obj?.id,
			name: obj?.name,
			employeeId: obj?.employeeId,
			receiptAmount: obj?.receiptAmount,
			status: obj?.status,
			claimType: obj?.claimType,
			reviewedBy: obj?.reviewedBy,
			submitDate: dayjs(obj?.submitDate),
			receiptDate: dayjs(obj?.receiptDate),
			description: obj?.description,
			datePaid: dayjs(obj?.datePaid),
			reason: obj?.reason,
			file: obj?.docs || ""
		});

		setVisible(true);
	}, [formik]);

	const isRejected = useMemo(() => formik.values.status === STATUS.REJECTED, [formik]);
	const isPaid = useMemo(() => formik.values.status === STATUS.PAID, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	const onHandleDownloadFile = useCallback(async (fileObject) => {
		if (downloadingFile.current) return;

		downloadingFile.current = true;

		let response = null;

		try {
			const payload = { "doc-id": fileObject.id };

			response = await api.post.humanResource.downloadClaimDoc(payload);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const url = response.fileUrl;
			const a = document.createElement("a");
			document.body.appendChild(a);

			a.href = url;
			a.download = response.fileName;
			a.click();

			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
				downloadingFile.current = false;
			}, 0);
		}
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-all-claim-history-modal" }} open={visible}>
			<div className="all-claim-history-modal">
				<button type="button" className="all-claim-history-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="all-claim-history-modal__title">Claim Details</h1>

				<div className="all-claim-history-modal__container">
					<div className="all-claim-history-modal__row">
						<AppInput disabled type="text" name="name" label="Name" value={formik.values.name} error={formik.errors.name} touched={formik.touched.name} onChange={formik.handleChange} />

						<AppInput disabled type="text" name="employeeId" label="Employee ID" value={formik.values.employeeId} error={formik.errors.employeeId} touched={formik.touched.employeeId} onChange={formik.handleChange} />
					</div>

					<div className="all-claim-history-modal__row">
						<AppInput disabled type="text" name="status" label="Status" value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />

						{isPaid && <AppInputDate disabled type="text" name="datePaid" label="Date Paid" value={formik.values.datePaid} error={formik.errors.datePaid} touched={formik.touched.datePaid} onChange={formik.handleChange} />}

						{isRejected && <AppInput disabled type="text" name="reviewedBy" label="Reject By" value={formik.values.reviewedBy} error={formik.errors.reviewedBy} touched={formik.touched.reviewedBy} onChange={formik.handleChange} />}
					</div>

					<div className="all-claim-history-modal__row">
						<AppInput disabled type="text" name="claimType" label="Claim Type" value={formik.values.claimType} error={formik.errors.claimType} touched={formik.touched.claimType} onChange={formik.handleChange} />

						<AppInput disabled type="text" name="receiptAmount" label="Amount (MYR)" value={formik.values.receiptAmount} error={formik.errors.receiptAmount} touched={formik.touched.receiptAmount} onChange={formik.handleChange} />
					</div>

					<div className="all-claim-history-modal__row">
						<AppInputDate disabled type="text" name="receiptDate" label="Receipt Date" value={formik.values.receiptDate} error={formik.errors.receiptDate} touched={formik.touched.receiptDate} onChange={formik.handleChange} />

						<AppInputDate disabled type="text" name="submitDate" label="Submit Date" value={formik.values.submitDate} error={formik.errors.submitDate} touched={formik.touched.submitDate} onChange={formik.handleChange} />
					</div>

					<AppInput disabled type="textarea" name="description" label="Description" multiline value={formik.values.description} touched={formik.touched.description} error={formik.errors.description} onChange={formik.handleChange} />

					{isRejected && <AppInput disabled type="textarea" name="reason" label="Reject Reason" multiline value={formik.values.reason} error={formik.errors.reason} touched={formik.touched.reason} onChange={formik.handleChange} />}

					<AppInputDragAndDropFiles disabled name="file" value={formik.values.file} onHandleDownloadFile={onHandleDownloadFile} onChange={formik.setFieldValue} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppAllClaimHistoryModal));
