import ROLES from "constants/roles";
import WORK_ORDER_TYPE from "constants/work-order-type";

import appHomeIcon from "components/icons/app-home-icon";
import appPaymentIcon from "components/icons/app-payment-icon";
import appCustomerIcon from "components/icons/app-customer-icon";
import appInventoryIcon from "components/icons/app-inventory-icon";
import appWorkOrderIcon from "components/icons/app-work-order-icon";
import appOperationsIcon from "components/icons/app-operations-icon";
import appHumanResourcesIcon from "components/icons/app-human-resources-icon";
import appReportsInquiryIcon from "components/icons/app-reports-inquiry-icon";
import appBulkDataHandlingIcon from "components/icons/app-bulk-data-handling-icon";
import appWorkCompletionReportIcon from "components/icons/app-work-completion-report-icon";
import appWorkInspectionListingIcon from "components/icons/app-work-inspection-listing-icon";

import pathnames from "../routes/pathnames";

const sideNavList = [
	{
		label: "Homepage",
		path: pathnames.home,
		icon: appHomeIcon,
		childrens: [],
		permission: null
	},
	{
		label: "Operations",
		path: pathnames.operations.userAccess,
		icon: appOperationsIcon,
		permission: null,
		childrens: [
			{
				label: "User Access",
				path: pathnames.operations.userAccess,
				relatives: [pathnames.operations.userAccess],
				permissions: [ROLES.ROLE, ROLES.USER_ACCESS]
			},
			{
				label: "Activity Logs",
				path: pathnames.activityLogs,
				relatives: [pathnames.activityLogs],
				permissions: []
			},
			{
				label: "Notifications",
				path: pathnames.notification.notifications,
				relatives: [pathnames.notification.notifications],
				permissions: []
			},
			{
				label: "General Configuration",
				path: pathnames.operations.generalConfiguration,
				relatives: [pathnames.operations.sparePartCreateEdit, pathnames.operations.serviceListingCreateEdit, pathnames.operations.serviceChecklistCreateEdit, pathnames.operations.contractCreateEdit, pathnames.operations.categoryCreateEdit, pathnames.operations.subCategoryCreateEdit],
				permissions: [ROLES.SPARE_PART_CONFIG, ROLES.CONTRACT_CONFIG, ROLES.INPUT_CONFIG, ROLES.SERVICE_CONFIG, ROLES.SERVICE_CHECKLIST_CONFIG]
			}
		]
	},
	{
		label: "Customer",
		path: pathnames.customer.customers,
		icon: appCustomerIcon,
		permission: null,
		childrens: [
			{
				label: "Customer Listing",
				path: pathnames.customer.customers,
				relatives: [pathnames.customer.customers, pathnames.customer.customerCreateEdit, pathnames.customer.mobileAppCreateEdit],
				permissions: [ROLES.CUSTOMER_MAINTENANCE, ROLES.CUSTOMER_USER, ROLES.CUSTOMER_CONTRACT, ROLES.CUSTOMER_CONTRACT_PIC, ROLES.CUSTOMER_CONTRACT_SITE, ROLES.CUSTOMER_CONTRACT_SITE_PIC]
			},
			{
				label: "Enquiries",
				path: pathnames.customer.enquiries,
				relatives: [pathnames.customer.enquiries, pathnames.customer.enquiriesCreateEdit],
				permissions: []
			},
			{
				label: "Customer Configuration",
				path: pathnames.customer.customerConfiguration,
				relatives: [pathnames.customer.customerConfiguration],
				permissions: []
			}
		]
	},
	{
		label: "Human Resources",
		path: pathnames.humanResources.remunerationPackages,
		icon: appHumanResourcesIcon,
		permission: null,
		childrens: [
			{
				label: "Remuneration Packages",
				path: pathnames.humanResources.remunerationPackages,
				relatives: [pathnames.humanResources.leavePackageCreateEdit, pathnames.humanResources.claimPackageCreateEdit],
				permissions: []
			},
			{
				label: "Employee Maintenance",
				path: pathnames.humanResources.employeeMaintenance,
				relatives: [pathnames.humanResources.employeeMaintenanceCreateEdit],
				permissions: [ROLES.EMPLOYEES]
			},
			{
				label: "Attendance Management",
				path: pathnames.humanResources.attendanceManagement,
				relatives: [pathnames.humanResources.attendanceManagement, pathnames.humanResources.viewAttendance],
				permissions: []
			},
			{
				label: "Leave Management",
				path: pathnames.humanResources.leaveManagement,
				relatives: [pathnames.humanResources.leaveManagement, pathnames.humanResources.hrViewHolidays, pathnames.humanResources.viewEmployeeLeave],
				permissions: []
			},
			{
				label: "Claim Management",
				path: pathnames.humanResources.claimManagement,
				relatives: [pathnames.humanResources.claimManagement, pathnames.humanResources.viewClaims],
				permissions: []
			},
			{
				label: "HR Configuration",
				path: pathnames.humanResources.hrConfiguration,
				relatives: [pathnames.humanResources.hrConfiguration],
				permissions: [ROLES.LEAVE_TYPE_CONFIG, ROLES.DEPARTMENT_CONFIG, ROLES.POSITION_CONFIG, ROLES.CLAIM_TYPE_CONFIG, ROLES.POSITION_LEVEL_CONFIG]
			}
		]
	},
	{
		label: "Work Inspection Listing",
		path: pathnames.workInspectionListing.workInspectionListing,
		icon: appWorkInspectionListingIcon,
		childrens: [],
		relatives: [pathnames.workInspectionListing.workInspectionListingCreateEdit],
		permission: null
	},
	{
		label: "Work Order",
		path: pathnames.workOrder.workOrderAll,
		icon: appWorkOrderIcon,
		permission: null,
		childrens: [
			{
				label: "All",
				path: pathnames.workOrder.workOrderAll,
				relatives: [pathnames.workOrder.workOrderAll],
				permissions: []
			},
			{
				label: "Preventive Work Order",
				path: pathnames.workOrder.workOrder + WORK_ORDER_TYPE.PREVENTIVE,
				relatives: [pathnames.workOrder.workOrderCreateEdit + WORK_ORDER_TYPE.PREVENTIVE],
				permissions: []
			},
			{
				label: "Corrective Work Order",
				path: pathnames.workOrder.workOrder + WORK_ORDER_TYPE.CORRECTIVE,
				relatives: [pathnames.workOrder.workOrderCreateEdit + WORK_ORDER_TYPE.CORRECTIVE],
				permissions: []
			},
			{
				label: "Breakdown Work Order",
				path: pathnames.workOrder.workOrder + WORK_ORDER_TYPE.BREAKDOWN,
				relatives: [pathnames.workOrder.workOrderCreateEdit + WORK_ORDER_TYPE.BREAKDOWN],
				permissions: []
			},
			{
				label: "Project Work Order",
				path: pathnames.workOrder.workOrder + WORK_ORDER_TYPE.PROJECT,
				relatives: [pathnames.workOrder.workOrderCreateEdit + WORK_ORDER_TYPE.PROJECT],
				permissions: []
			}
		]
	},
	{
		label: "Work Completion Report",
		path: pathnames.workCompletionReport.workCompletionReport,
		icon: appWorkCompletionReportIcon,
		childrens: [],
		relatives: [pathnames.workCompletionReport.workCompletionReportCreateEdit],
		permission: []
	},
	{
		label: "Inventory",
		path: pathnames.inventory.inventories,
		icon: appInventoryIcon,
		permission: null,
		childrens: [
			{
				label: "Inventory Listing",
				path: pathnames.inventory.inventories,
				relatives: [pathnames.inventory.inventories, pathnames.inventory.inventoryCreateEdit],
				permissions: []
			},
			{
				label: "Inventory History",
				path: pathnames.inventory.inventoryHistory,
				relatives: [pathnames.inventory.inventoryHistory],
				permissions: []
			},
			{
				label: "Inventory Configuration",
				path: pathnames.inventory.inventoryConfiguration,
				relatives: [pathnames.inventory.inventoryConfiguration],
				permissions: [ROLES.INVENTORY_LOCATION]
			}
		]
	},
	{
		label: "Sales",
		path: pathnames.sales.quotations,
		icon: appPaymentIcon,
		permission: null,
		childrens: [
			{
				label: "Quotations",
				path: pathnames.sales.quotations,
				relatives: [pathnames.sales.quotations, pathnames.sales.quotationCreateEdit],
				permissions: []
			},
			{
				label: "Sales Orders",
				path: pathnames.sales.salesOrders,
				relatives: [pathnames.sales.salesOrders, pathnames.sales.salesOrderCreateEdit, pathnames.sales.salesOrderDeliveryOrderCreateEdit],
				permissions: []
			},
			{
				label: "Delivery Orders",
				path: pathnames.sales.deliveryOrders,
				relatives: [pathnames.sales.deliveryOrders, pathnames.sales.deliveryOrdersViewEdit],
				permissions: []
			},
			{
				label: "Invoices",
				path: pathnames.sales.invoices,
				relatives: [pathnames.sales.invoices, pathnames.sales.invoiceCreateEdit],
				permissions: []
			}
		]
	},
	{
		label: "Reports & Inquiry",
		path: pathnames.reportExtraction,
		icon: appReportsInquiryIcon,
		permission: null,
		childrens: [
			{
				label: "Report Extraction",
				path: pathnames.reportExtraction,
				relatives: [pathnames.reportExtraction],
				permissions: []
			},
			{
				label: "Data Inquiry",
				path: pathnames.dataInquiry,
				relatives: [pathnames.dataInquiry],
				permissions: []
			}
		]
	},
	{
		label: "Bulk Data Handling",
		path: pathnames.bulkDataHandlingCustomer,
		icon: appBulkDataHandlingIcon,
		permission: null,
		childrens: [
			{
				label: "Customer",
				path: pathnames.bulkDataHandlingCustomer,
				relatives: [pathnames.bulkDataHandlingCustomer],
				permissions: []
			},
			{
				label: "Human Resources",
				path: pathnames.bulkDataHandlingHumanResources,
				relatives: [pathnames.bulkDataHandlingHumanResources],
				permissions: []
			},
			{
				label: "Inventory",
				path: pathnames.bulkDataHandlingInventory,
				relatives: [pathnames.bulkDataHandlingInventory],
				permissions: []
			}
		]
	}
];

export default sideNavList;
