import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";
import { Menu, Table, TableBody, TableCell, TableRow } from "@mui/material";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import ClassNames from "common/class-names";
import getTotalDays from "common/get-total-days";
import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppInputDate from "components/app-input-date";
import AppTable from "components/app-table/app-table";

import filerIcon from "assets/images/filter-icon.png";
import searchIcon from "assets/images/search-icon.png";
import rejectIcon from "assets/images/pages/customer/reject-icon.svg";
import approveIcon from "assets/images/pages/customer/approve-icon.svg";

const UserApplicationsTable = () => {
	const dispatch = useDispatch();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", sort: searchParams.get("sort") || "", tab: "USER_APPLICATIONS" });
	const [data, setData] = useState(convertPaginationTableData());
	const [selectedPending, setSelectedPending] = useState([]);
	const [menuAnchor, setMenuAnchor] = useState(null);

	const validationSchema = yup.object({
		endDate: yup.date().test(function (value, { parent }) {
			if (!value) return true;

			const totalDays = getTotalDays(parent.startDate, value);

			return totalDays >= 0 && totalDays <= 90;
		})
	});

	const formik = useFormik({
		initialValues: { startDate: "", endDate: "" },
		validationSchema: validationSchema,
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const onHandleGetList = useCallback(async () => {
		setSelectedPending([]);

		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);

			const modifiedParams = sanitizeObject({ ...queryParams.current, size: 10, name: queryParams.current.value, email: queryParams.current.value, status: `${STATUS.PENDING},${STATUS.REJECTED}` });

			response = await api.get.mobile.users(modifiedParams);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	// prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		queryParams.current["start-date"] = dayjs(values.startDate).isValid() ? dayjs(values.startDate).format(DATE_TIME.YYYY_MM_DD_HH_MM_SS) : "";
		queryParams.current["end-date"] = dayjs(values.endDate).isValid() ? dayjs(values.endDate).format(DATE_TIME.YYYY_MM_DD_HH_MM_SS) : "";

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const onHandleApproval = useCallback(async (decision) => {
		let response = null;

		try {
			const payload = { ids: selectedPending, decision: decision };

			await api.post.mobile.approval(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {

			dispatch(promptLayoutAlertMessage({ message: `Mobile App Customer was ${decision.toLowerCase()} successfully!` }));

			onHandleGetList();
		}
	}, [onHandleGetList, selectedPending, dispatch]);

	//prettier-ignore
	const intructionClassName = useMemo(() => ClassNames({
		"filter-menu__instruction": true,
		"filter-menu__instruction--error": formik.errors.endDate,
	}), [formik.errors.endDate]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleDebounceSearch = debounce(onHandleSearch, 1000);

	const onToggleFilterMenu = useCallback((event) => {
		setMenuAnchor(event.currentTarget);
	}, []);

	const onHandleCloseMenu = useCallback(() => {
		setMenuAnchor(null);
	}, []);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current["start-date"] = "";
		queryParams.current["end-date"] = "";

		onHandleGetList();
	}, [formik, onHandleGetList]);

	const onHandleSelectPending = useCallback((boolean, id) => {
		setSelectedPending((prev) => {
			let nextPending = [...prev];

			if (boolean) {
				nextPending.push(id);
			} else {
				nextPending = nextPending.filter((pendingID) => pendingID !== id);
			}

			return nextPending;
		});
	}, []);

	// prettier-ignore
	const onHandleSelectAll = useCallback((boolean) => {
		const ids = data.content.map((item) => item.id);

		setSelectedPending(boolean ? ids : []);
	}, [data]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "checkbox",
			options: {
				sort: false,
				customHeadRender: () => {
					const selectedAll = data.content.length > 0 && selectedPending.length === data.content.length;

					return (
						<TableCell key="table-key" className="app-table__cell app-table__cell--header">
							<AppCheckbox onClick={onHandleSelectAll} value={selectedAll} />
						</TableCell>
					);
				},
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const tablePendingID = data.content[rowIndex]?.id;
					const selected = selectedPending.includes(tablePendingID);
					const status = data.content[rowIndex]?.status;

					if (status === STATUS.REJECTED) {
						return null;
					}

					return (
						<Table>
							<TableBody>
								<TableRow>
									<TableCell className="app-table__cell">
										<AppCheckbox className="checkbox" onClick={(v) => onHandleSelectPending(v, tablePendingID)} value={selected} />
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					);
				}
			}
		},
		{
			name: "fullName",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "email",
			label: "Email Address",
			options: {
				sort: false
			}
		},
		{
			name: "mobileNo",
			label: "Mobile Number",
			options: {
				sort: false
			}
		},
		{
			name: "createdDate",
			label: "Submit Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY_HH_MM_A)
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: false,
				customBodyRender: (value) => <AppStatus status={value} />
			}
		}
	], [data, onHandleSelectAll, onHandleSelectPending, selectedPending]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch(action) {
				case "changePage":
					queryParams.current.page = tableState.page;
					
					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) } 

					onHandleGetList();
					break;
				default:
					break;    
			}
		}
	}), [data, onHandleGetList]);

	const HeaderApprovalButton = useCallback((obj) => {
		if (!obj.selectedPending.length) return null;

		return (
			<div className="user-applications-table__header-button">
				<div className="user-applications-table__reject-button">
					<AppButton outline type="button" label="Reject" icon={rejectIcon} onClick={() => obj.onHandleApproval(STATUS.REJECTED)} />
				</div>

				<div className="user-applications-table__approve-button">
					<AppButton type="button" label="Approve" icon={approveIcon} onClick={() => obj.onHandleApproval(STATUS.APPROVED)} />
				</div>
			</div>
		);
	}, []);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.MOBILE.USERS);
		};
	}, [cancelRequest]);

	return (
		<div className="app-user-applications-table">
			<div className="user-applications-table">
				<div className="user-applications-table__header">
					<div className="user-applications-table__search-input">
						<AppInput type="text" name="search" placeholder="Search by Name or Email Address" endIcon={searchIcon} iconPosition="end" defaultValue={queryParams.current.keyword} onChange={onHandleDebounceSearch} />

						<div className="user-applications-table__filter-button">
							<AppButton outline type="button" label="Filter" icon={filerIcon} onClick={onToggleFilterMenu} />
						</div>
					</div>

					<HeaderApprovalButton selectedPending={selectedPending} onHandleApproval={onHandleApproval} />
				</div>

				<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
			</div>

			{/* prettier-ignore */}
			<Menu classes={{ root: "app-user-applications-table-filter-menu" }} anchorEl={menuAnchor} open={!!menuAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<div className="filter-menu">
					<div className="filter-menu__body">
						<div className="filter-menu__inputs">
							<AppInputDate name="startDate" label="Submit Date" placeholder="Start Date" value={formik.values.startDate} onChange={formik.setFieldValue} />

							<AppInputDate name="endDate" placeholder="End Date" value={formik.values.endDate} onChange={formik.setFieldValue} />
						</div>

						<p className={intructionClassName}>Please select a date or a date range of up to 90 days</p>
					</div>

					<div className="filter-menu__footer">
						<button type="button" className="filter-menu__button" onClick={onHandleClearFilter}>
							Clear All Filters
						</button>

						<div className="filter-menu__buttons">
							<button type="button" className="filter-menu__button filter-menu__button--cancel" onClick={onHandleCloseMenu}>
								Cancel
							</button>

							<button type="button" className="filter-menu__button filter-menu__button--apply" onClick={formik.handleSubmit}>
								Apply
							</button>
						</div>
					</div>
				</div>
			</Menu>
		</div>
	);
};

export default UserApplicationsTable;
