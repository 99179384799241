import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { useFormik } from "formik";
import Menu from "@mui/material/Menu";
import { useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import { AxiosContext } from "contexts/with-interceptor-provider";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import PAGE from "constants/page";
import ENDPOINT_PATH from "constants/end-point-path";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppAddServicesModal from "components/pages/work-order/app-add-services-modal";
import AppCreateEditServiceModal from "components/pages/work-order/app-create-edit-service-modal";
import AppEditServiceListingModal from "components/pages/work-order/app-edit-service-listing-modal";
import AppAddServiceChecklistModal from "components/pages/work-order/app-add-service-checklist-modal";
import AppReorderServiceLinesModal from "components/pages/work-order/app-reorder-service-lines-modal";
import AppDeleteServiceListingModal from "components/pages/work-order/app-delete-service-listing-modal";
import AppWorkOrderImportFromCustomerModal from "components/pages/work-order/app-work-order-import-from-customer-modal";

import editIcon from "assets/images/edit-icon.png";
import trashIcon from "assets/images/trash-icon.png";
import addIcon from "assets/images/blue-add-icon.svg";
import chevronUpIcon from "assets/images/chevron-up-icon.png";
import reorderingIcon from "assets/images/reordering-icon.svg";
import chevronDownGreyIcon from "assets/images/chevron-down-grey-icon.png";
import verticalBreadCrumbsIcon from "assets/images/vertical-breadcrumbs-icon.png";
import importFromCustomerIcon from "assets/images/pages/work-order/import-from-customer-icon.svg";

const AppWorkOrderServices = (props, ref) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const navigate = useNavigate();
	const selectedItem = useRef();
	const addServicesModal = useRef();
	const createEditServiceModalRef = useRef();
	const editServiceListingModal = useRef();
	const serviceChecklistModalRef = useRef();
	const reorderServiceLinesModal = useRef();
	const importFromCustomerModal = useRef();
	const deleteServiceListingModal = useRef();
	const [searchParams, setSearchParams] = useSearchParams();
	const [selectedRowItem, setSelectedRowItem] = useState(null);
	const [data, setData] = useState(convertPaginationTableData());
	const [serviceTableAnchor, setServiceTableAnchor] = useState(null);
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const submitLabel = useMemo(() => (!isCreate ? "Update" : "Create"), [isCreate]);
	const assetId = useMemo(() => props.assetId, [props.assetId]);
	const siteName = useMemo(() => props.siteName, [props.siteName]);
	const memoSearchParams = useRef(setSearchParams);
	const queryParams = useRef({ page: 0, value: searchParams.get("value") || "", sort: searchParams.get("sort") || "" });

	const formik = useFormik({ initialValues: { items: data, serviceRemarks: "" } });

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10, "work-order-id": id });

			memoSearchParams.current(params);

			response = await api.get.workOrder.serviceListing(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, [id]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.value = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	//prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		props.onSubmit(values);
	}, [props]);

	const onHandleBack = useCallback(() => {
		navigate(pathnames.workOrder.preventiveMaintenance);
	}, [navigate]);

	//prettier-ignore
	const onToggleTableAction = useCallback((event, tableMeta) => {
		selectedItem.current = { ...data.content[tableMeta.rowIndex], rowIndex: tableMeta.rowIndex };

		setServiceTableAnchor(event.currentTarget);
	}, [data]);

	const onHandleAddChecklist = () => {
		serviceChecklistModalRef.current.onHandleShow();
	};

	const onHandleReorderServiceLines = useCallback(() => {
		reorderServiceLinesModal.current.onHandleShow(data.content);
	}, [data.content]);

	const onHandleImportFromCustomer = useCallback(() => {
		importFromCustomerModal.current.onHandleShow({ assetId, siteName });
	}, [assetId, siteName]);

	const onHandleOpenExistingServices = useCallback(() => {
		addServicesModal.current.onHandleShow({ assetId: assetId });
	}, [assetId]);

	const onHandleCreateService = () => {
		createEditServiceModalRef.current.onHandleShow();
	};

	const onHandleEditServiceMenu = useCallback(() => {
		createEditServiceModalRef.current.onHandleShow(selectedItem.current);

		setServiceTableAnchor(null);
	}, []);

	const onHandleShowDeleteServiceListingModal = useCallback(() => {
		deleteServiceListingModal.current.onHandleShow(selectedItem.current);

		setServiceTableAnchor(null);
	}, []);

	//prettier-ignore
	const onHandleDeleteServiceListing = useCallback(async (service) => {
		let response = null;

		try {
			const payload = {
				workOrderId: id,
				woServices: [service]
			};

			await api.post.workOrder.service(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			onHandleGetList();

			dispatch(promptLayoutAlertMessage({ message: "Service was successfully deleted!" }));
		}
	}, [dispatch, id, onHandleGetList]);

	const onHandleCloseServiceTableMenu = useCallback(() => {
		setServiceTableAnchor(null);
	}, []);

	//prettier-ignore
	const onHandleCellSelect = useCallback((cell, cellData) => {
		if (cell?.type) return;

		const item = data.content[cellData.rowIndex];

		if (item) setSelectedRowItem(item);
	}, [data]);

	//prettier-ignore
	const onHandleSwitchService = useCallback((value) => {
		const currentRowNumber = selectedRowItem?.paginationNumbers;
		const selectedItemList = data.content.filter((o) => o.paginationNumbers === currentRowNumber + value);

		if (selectedItemList.length) setSelectedRowItem(selectedItemList[0]);
	}, [selectedRowItem, data.content]);

	const onHandleProcessServices = useCallback((selectedServices) => {
		let processedServices = [];

		processedServices = selectedServices.map((o) => {
			let processedSubtasks = [];

			processedSubtasks = o.subtasks.map((subtask) => {
				return {
					subtaskAction: subtask.subtaskAction,
					configInputId: subtask.input.id
				};
			});

			return {
				serviceType: o.serviceType,
				configInputId: o.input.id,
				serviceAction: o.serviceAction,
				serviceRemark: o.remarks,
				serviceSubtask: processedSubtasks,
				deleted: false
			};
		});

		return processedServices;
	}, []);

	// prettier-ignore
	const onHandleAddServices = useCallback(async (selectedServices) => {
		const processedServices = onHandleProcessServices(selectedServices);

		let response = null;

		try {
			const payload = {
				workOrderId: id,
				woServices: processedServices
			};

			await api.post.workOrder.service(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			onHandleGetList();

			dispatch(promptLayoutAlertMessage({ message: "Services were added successfully!" }));
		}
	}, [dispatch, id, onHandleGetList, onHandleProcessServices]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "serviceAction",
			label: "Service Action",
			options: {
				sort: false
			}
		},
		{
			name: "serviceType",
			label: "Service Type",
			options: {
				sort: false
			}
		},
		{
			name: "serviceInput.label",
			label: "Input",
			options: {
				sort: false
			}
		},
		{
			name: "edit",
			label: "Action",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={(event) => onToggleTableAction(event, tableMeta)}>
							<img src={verticalBreadCrumbsIcon} alt="edit-icon" />
						</button>
					);
				}
			}
		}
	], [onToggleTableAction]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		expandableRowsHeader: false,
		expandableRowsOnClick: false,
		onCellClick: onHandleCellSelect,
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		enableNestedDataAccess: ".",
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data.page, data.totalElements, onHandleCellSelect, onHandleGetList]);

	const SelectedItemDetails = useCallback((obj) => {
		if (!obj.selectedRowItem) return null;

		return (
			<Fragment>
				<div className="work-order-services__item-table">
					<div className="item-table">
						<div className="item-table__content">
							<div className="item-table__item">
								<p className="item-table__label">Service Action</p>

								<p className="item-table__value">{obj.selectedRowItem.serviceAction}</p>
							</div>

							<div className="item-table__item">
								<p className="item-table__label">Service Input</p>

								<p className="item-table__value">{obj.selectedRowItem.serviceInput.type}</p>
							</div>

							<div className="item-table__item item-table__item--subtasks">
								<p className="item-table__label">#</p>

								<p className="item-table__label item-table__label--subtasks">Sub-Tasks</p>

								<p className="item-table__label item-table__label--subtasks">Subtask Input</p>
							</div>

							{obj.selectedRowItem?.subtasks?.map((o) => {
								return (
									<div key={o.id} className="item-table__item item-table__item--subtasks">
										<p className="item-table__label">{o.seq}</p>

										<p className="item-table__label item-table__label--subtasks">{o.subtaskAction}</p>

										<p className="item-table__label item-table__label--subtasks">{o.input?.label}</p>
									</div>
								);
							})}
						</div>

						<div className="item-table__switch-service">
							<AppButton label="" type="button" icon={chevronUpIcon} onClick={() => obj.onHandleSwitchService(-1)} />

							<AppButton label="" type="button" icon={chevronDownGreyIcon} onClick={() => obj.onHandleSwitchService(1)} />
						</div>
					</div>
				</div>

				<div className="item-table__remark">
					<AppInput multiline type="textarea" name="serviceRemarks" label="Service Remarks" placeholder="Enter Service Remarks" value={obj.values.serviceRemarks} error={obj.errors.serviceRemarks} touched={obj.touched.serviceRemarks} onChange={obj.onChange} />
				</div>
			</Fragment>
		);
	}, []);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.WORK_ORDER.SERVICE_LISTING);
		};
	}, [cancelRequest]);

	return (
		<div className="app-work-order-services">
			<div className="work-order-services">
				<div className="work-order-services__container">
					<div className="work-order-services__row work-order-services__row--divider">
						<div className="work-order-services__column">
							<p className="work-order-services__label">
								Add Service Checklists <span className="work-order-services__required">*</span>
							</p>

							<AppButton outline type="button" label="Add Existing Checklist(s)" onClick={onHandleAddChecklist} />
						</div>

						<div className="work-order-services__column">
							<p className="work-order-services__label work-order-services__label--services">
								Add Services <span className="work-order-services__required">*</span>
							</p>

							<AppButton outline type="button" label="Add Existing Service(s)" onClick={onHandleOpenExistingServices} />

							<p className="work-order-services__text">Or</p>

							<AppButton outline type="button" label="Add New Service" icon={addIcon} onClick={onHandleCreateService} />
						</div>
					</div>

					<p className="work-order-services__label work-order-services__label--services">Service Lines</p>

					<div className="work-order-services__column">
						<div className="work-order-services__header">
							<AppTableFilterHeader searchPlaceholder="Search by Service ID or Service Name" searchDefaultValue={queryParams.current.value} onHandleSearch={onHandleSearch} />

							<AppButton outline type="button" label="Import from Customer" icon={importFromCustomerIcon} onClick={onHandleImportFromCustomer} />

							<AppButton outline type="button" label="Reorder" icon={reorderingIcon} onClick={onHandleReorderServiceLines} />
						</div>

						<AppTable data={data.content} columns={tableColumns} options={tableOptions} />

						<SelectedItemDetails selectedRowItem={selectedRowItem} onHandleSwitchService={onHandleSwitchService} values={formik.values} errors={formik.errors} touched={formik.touched} onChange={formik.handleChange} />
					</div>
				</div>

				<div className="work-order-services__button-container">
					<AppButton outline type="button" label="Cancel" onClick={onHandleBack} />

					<AppButton type="button" label={submitLabel} onClick={onHandleSubmit} />
				</div>
			</div>

			{/* prettier-ignore */}
			<Menu classes={{ root: "work-order-services-table-menu" }} anchorEl={serviceTableAnchor} open={!!serviceTableAnchor} onClose={onHandleCloseServiceTableMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem onClick={onHandleEditServiceMenu}><img src={editIcon} alt="services-edit" />Edit</MenuItem>

				<MenuItem onClick={onHandleShowDeleteServiceListingModal}><img className="work-order-services-table-menu__close-icon" src={trashIcon} alt="services-close" />Delete</MenuItem>
			</Menu>

			<AppAddServicesModal ref={addServicesModal} onHandleAddServices={onHandleAddServices} />

			<AppDeleteServiceListingModal ref={deleteServiceListingModal} onConfirm={onHandleDeleteServiceListing} />

			<AppEditServiceListingModal ref={editServiceListingModal} />

			<AppWorkOrderImportFromCustomerModal ref={importFromCustomerModal} onHandleAddServices={onHandleAddServices} />

			<AppReorderServiceLinesModal ref={reorderServiceLinesModal} onConfirm={() => {}} />

			<AppCreateEditServiceModal ref={createEditServiceModalRef} onHandleGetList={onHandleGetList} />

			<AppAddServiceChecklistModal ref={serviceChecklistModalRef} onHandleAddServices={onHandleAddServices} />
		</div>
	);
};

export default AppWorkOrderServices;
