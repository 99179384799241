import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { useFormik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import PAGE from "constants/page";
import STATUS from "constants/status";
import ENDPOINT_PATH from "constants/end-point-path";

import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

import editIcon from "assets/images/edit-icon.png";

const PageWorkCompletionReport = () => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const memoSearchParams = useRef(setSearchParams);
	const [data, setData] = useState(convertPaginationTableData());
	const defaultStatus = useMemo(() => [STATUS.OPEN, STATUS.COMPLETED, STATUS.VERIFIED, STATUS.ACKNOWLEDGED, STATUS.CANCELLED].join(","), []);
	const status = useMemo(() => {
		const currentStatuses = searchParams.get("status")?.split(",");
		const relevantStatus = currentStatuses?.every((e) => defaultStatus.split(",").includes(e));

		if (!relevantStatus) {
			return defaultStatus;
		} else {
			return searchParams.get("status");
		}
	}, [defaultStatus, searchParams]);
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", status: status, sort: searchParams.get("sort") || "" });

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			memoSearchParams.current(params);

			response = await api.get.workCompletionReport.reports(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		queryParams.current.status = values.status.join(",");

		onHandleGetList();
	}, [onHandleGetList]);

	const formik = useFormik({
		initialValues: {
			search: "",
			status: [STATUS.OPEN, STATUS.COMPLETED, STATUS.VERIFIED, STATUS.ACKNOWLEDGED, STATUS.CANCELLED]
		},
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Open", name: STATUS.OPEN },
			{ label: "Completed", name: STATUS.COMPLETED },
			{ label: "Verified", name: STATUS.VERIFIED },
			{ label: "Acknowledged", name: STATUS.ACKNOWLEDGED },
			{ label: "Cancelled", name: STATUS.CANCELLED }
		];

		return data;
	}, []);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.status ? queryParams.current.status.split(",") : []);
	}, [formik]);

	//prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter(o => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current.status = defaultStatus;

		onHandleGetList();
	}, [formik, onHandleGetList, defaultStatus]);

	const onHandleCreateReport = useCallback(() => {
		navigate(pathnames.workCompletionReport.workCompletionReportCreateEdit + PAGE.CREATE);
	}, [navigate]);

	//prettier-ignore
	const onHandleEditReport = useCallback((obj) => {
		const id = data.content[obj.rowIndex]?.id;

		navigate(pathnames.workCompletionReport.workCompletionReportCreateEdit + id);
	}, [data, navigate]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "referenceNo",
			label: "Work Completion ID",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "registeredName",
			label: "Registered Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "workOrder.customerDetail.customerContractSiteName",
			label: "Site Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					const rowData = data.content[tableMeta.rowIndex];
					const siteName = rowData.customerContractSiteName;

					return siteName;
				},				
			}
		},
		{
			name: "asset.assetName",
			label: "Asset Name",
			options: {
				sort: false
			}
		},
		{
			name: "workOrder.teamPic.personalInfo.fullName",
			label: "Prepared By",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value, tableMeta) => {
					const rowData = data.content[tableMeta.rowIndex];
					const preparedBy = rowData.preparedBy?.personalInfo?.fullName;

					return preparedBy;
				},
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => <AppStatus status={value} />
			}
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__edit" onClick={() => onHandleEditReport(tableMeta)}>
							<img src={editIcon} alt="edit-icon" />
						</button>
					);
				}
			}
		}
	], [onHandleEditReport, data]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		enableNestedDataAccess: ".",
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;
					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };
					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.WORK_COMPLETION_REPORT.REPORTS);
		};
	}, [cancelRequest]);

	return (
		<div className="page-work-completion-report">
			<div className="work-completion-report">
				<h1 className="work-completion-report__title">Work Completion Report</h1>

				<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Asset Name or WCR ID" searchDefaultValue={queryParams.current.keyword} onHandleSearch={onHandleSearch} onHandleAdd={onHandleCreateReport} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
					<div className="work-completion-report-filter">
						<div className="work-completion-report-filter__container">
							<p className="work-completion-report-filter__label">Status</p>

							<div className="work-completion-report-filter__checkbox-status">
								{menuFilterStatus.map((o) => {
									const isActiveStatus = formik.values.status.findIndex((i) => i === o.name) > -1;

									return (
										<div className="work-completion-report-filter__checkbox" key={o.label}>
											<AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActiveStatus} />
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</AppTableFilterHeader>

				<div className="work-completion-report__body">
					<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
				</div>
			</div>
		</div>
	);
};

export default PageWorkCompletionReport;
