import PageViewClaims from "pages/human-resources/page-view-claims";
import PageViewAttendance from "pages/human-resources/page-view-attendance";
import PageHrViewHolidays from "pages/human-resources/page-hr-view-holidays";
import PageClaimManagement from "pages/human-resources/page-claim-management";
import PageHrConfiguration from "pages/human-resources/page-hr-configuration";
import PageLeaveManagement from "pages/human-resources/page-leave-management";
import PageViewEmployeeLeave from "pages/human-resources/page-view-employee-leave";
import PageEmployeeMaintenance from "pages/human-resources/page-employee-maintenance";
import PageAttendanceManagement from "pages/human-resources/page-attendance-management";
import PageRemunerationPackages from "pages/human-resources/page-renumeration-packages";
import PageClaimPackageCreateEdit from "pages/human-resources/page-claim-package-create-edit";
import PageLeavePackageCreateEdit from "pages/human-resources/page-leave-package-create-edit";
import PageEmployeeMaintenanceCreateEdit from "pages/human-resources/page-employee-maintenance-create-edit";

import pathnames from "routes/pathnames";

import ROLES from "constants/roles";

const humanResourceRoutes = [
	{
		path: pathnames.humanResources.remunerationPackages,
		element: <PageRemunerationPackages />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.humanResources.leavePackageCreateEdit + ":id",
		element: <PageLeavePackageCreateEdit />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.humanResources.claimPackageCreateEdit + ":id",
		element: <PageClaimPackageCreateEdit />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.humanResources.employeeMaintenance,
		element: <PageEmployeeMaintenance />,
		authenticated: true,
		role: [ROLES.EMPLOYEES]
	},
	{
		path: pathnames.humanResources.employeeMaintenanceCreateEdit + ":id",
		element: <PageEmployeeMaintenanceCreateEdit />,
		authenticated: true,
		role: [ROLES.EMPLOYEES]
	},
	{
		path: pathnames.humanResources.attendanceManagement,
		element: <PageAttendanceManagement />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.humanResources.leaveManagement,
		element: <PageLeaveManagement />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.humanResources.viewAttendance + ":id",
		element: <PageViewAttendance />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.humanResources.viewEmployeeLeave + ":id",
		element: <PageViewEmployeeLeave />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.humanResources.viewClaims + ":id",
		element: <PageViewClaims />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.humanResources.hrViewHolidays,
		element: <PageHrViewHolidays />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.humanResources.claimManagement,
		element: <PageClaimManagement />,
		authenticated: true,
		role: null
	},
	{
		path: pathnames.humanResources.hrConfiguration,
		element: <PageHrConfiguration />,
		authenticated: true,
		role: [ROLES.LEAVE_TYPE_CONFIG, ROLES.DEPARTMENT_CONFIG, ROLES.POSITION_CONFIG, ROLES.CLAIM_TYPE_CONFIG, ROLES.POSITION_LEVEL_CONFIG]
	}
];

export default humanResourceRoutes;
