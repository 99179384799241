import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";
import getStateListing from "services/get-state-listing";
import getRegionListing from "services/get-region-listing";
import getPostcodeListing from "services/get-postcode-listing";
import getWorkSpaceListing from "services/get-workspace-listing";
import getAssetTypeListing from "services/get-asset-type-listing";
import getSiteAssetListing from "services/get-site-asset-listing";
import getSalesOrderListing from "services/get-sales-order-listing";
import getCustomerPicListing from "services/get-customer-pic-listing";
import getBuildingTypeListing from "services/get-building-type-listing";
import getContractSitesListing from "services/get-contract-sites-listing";
import getContractByPicListing from "services/get-contract-by-pic-listing";
import getCustomerLookupListing from "services/get-customer-lookup-listing";
import getWorkInspectionListing from "services/get-work-inspection-listing";
import getEmployeeMaintenanceListing from "services/get-employee-maintenance-listing";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import combineDateTime from "common/combine-date-time";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import STATUS from "constants/status";
import CONTRACT_TYPE from "constants/contract-type";
import ENDPOINT_PATH from "constants/end-point-path";
import SIGNATURE_TYPE from "constants/signature-type";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppInputDate from "components/app-input-date";
import AppInputTime from "components/app-input-time";
import AppRadioInput from "components/app-radio-input";
import AppMobileInput from "components/app-mobile-input";
import AppSelectInput from "components/app-select-input";
import AppInputDragAndDrop from "components/app-input-drag-and-drop";
import AppWorkOrderHoldOnModal from "components/pages/work-order/app-work-order-hold-on-modal";
import AppSiteDetailsTeamMembersTable from "components/pages/work-order/app-site-details-team-members-table";

const AppWorkOrderSiteDetails = (props) => {
	const { orderType, id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const profile = useSelector((state) => state.profile);
	const [signatureType, setSignatureType] = useState("");
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_MAINTENANCE], [profile]); // TODO: Change when WO Role is available
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const submitLabel = useMemo(() => (isCreate ? "Create" : "Update"), [isCreate]);
	const teamMembersTableRef = useRef();
	const holdOnModal = useRef();

	const statusOptions = [
		{ value: STATUS.DRAFT, label: "Draft" },
		{ value: STATUS.PENDING_ASSIGN, label: "Pending Assignation" },
		{ value: STATUS.PENDING_ASSETS, label: "Pending Assets" },
		{ value: STATUS.OPEN, label: "Open" },
		{ value: STATUS.IN_PROGRESS, label: "In Progress" },
		{ value: STATUS.PENDING_ACKNOWLEDGE, label: "Pending Acknowledge" },
		{ value: STATUS.PENDING_VERIFICATION, label: "Pending Verification" },
		{ value: STATUS.VERIFIED, label: "Verified" },
		{ value: STATUS.COMPLETED, label: "Completed" },
		{ value: STATUS.PENDING_RESCHEDULE, label: "Pending Rescheduled" },
		{ value: STATUS.RESCHEDULED, label: "Rescheduled" },
		{ value: STATUS.CANCELLED, label: "Cancelled" }
	];

	//prettier-ignore
	const contractTypeOptions = useMemo(() => [
		{ label: "Contracted", value: "CONTRACTED" },
		{ label: "Non-Contracted", value: "NON_CONTRACTED" }
	], []);

	const initialValues = useMemo(() => {
		let values = {
			id: "",
			city: "",
			email: "",
			state: "",
			status: STATUS.DRAFT,
			workOrderType: "",
			referenceNo: "",
			teamPIC: "",
			duration: "",
			postcode: "",
			prefixNo: "",
			mobileNo: "",
			siteName: "",
			siteId: "",
			assetId: "",
			createdBy: "",
			preparedDate: "",
			workSpace: "",
			workSpaceId: "",
			contractId: "",
			buildingType: "",
			customerId: "",
			customerPic: "",
			customerPicId: "",
			salesOrderId: "",
			addressLine1: "",
			addressLine2: "",
			type: CONTRACT_TYPE.CONTRACTED,
			overallJobDescription: "",
			workInspectionId: "",
			issueDescription: "",
			workCompletionId: "",
			customerAssetName: "",
			targetCompletionDate: "",
			targetCompletionTime: "",
			workInspectionCheckbox: false,
			endDateTime: null,
			startDate: "",
			startTime: "",
			endDate: "",
			endTime: "",
			scheduledTime: null,
			scheduledDate: null,
			teamMembers: [],
			woAsset: [],
			preparedBy: { employeeId: "", name: "", signature: "", signatureFile: null },
			completedBy: { employeeId: "", name: "", signature: "", signatureFile: null },
			verifiedBy: { employeeId: "", name: "", signature: "", signatureFile: null },
			acknowledgedBy: { employeeId: "", name: "", signature: "", signatureFile: null },
			rescheduledBy: { employeeId: "", name: "", signature: "", signatureFile: null },
			cancelledBy: { employeeId: "", name: "", signature: "", signatureFile: null },
			pendingRescheduledBy: { employeeId: "", name: "", signature: "", signatureFile: null }
		};

		if (props.values) {
			values = props.values;
		}

		return values;
	}, [props.values]);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			type: yup.string().required(ERRORS.REQUIRED),
			customerId: yup.string().required(ERRORS.REQUIRED),
			contractId: yup.string().required(ERRORS.REQUIRED),
			siteId: yup.string().when(["type"], {
				is: (type) => type === CONTRACT_TYPE.CONTRACTED,
				then: () => yup.string().required(ERRORS.REQUIRED)
			}),
			siteName: yup.string().when(["type"], {
				is: (type) => type === CONTRACT_TYPE.NON_CONTRACTED,
				then: () => yup.string().required(ERRORS.REQUIRED)
			}),
			assetId: yup.string().required(ERRORS.REQUIRED),
			overallJobDescription: yup.string().required(ERRORS.REQUIRED),
			issueDescription: yup.string().required(ERRORS.REQUIRED),
			targetCompletionDate: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
			targetCompletionTime: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
			scheduledDate: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
			scheduledTime: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	const isContract = useMemo(() => formik.values.type === CONTRACT_TYPE.CONTRACTED, [formik.values.type]);
	const checkBoxDisable = useMemo(() => !isCreate || !isContract || !formik.values.workInspectionId, [formik.values.workInspectionId, isContract, isCreate]);
	const contractCondition = useMemo(() => {
		if (isContract) {
			return !formik.values.siteId;
		} else {
			return false;
		}
	}, [formik.values.siteId, isContract]);

	//prettier-ignore
	const onHandleGetDetails = useCallback(async (workOrderReportId) => {
		let response = null;

		try {
			response = await api.get.workOrder.workOrder(workOrderReportId);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			memoSetFormValues({
				id: response.id,
				city: response.customerDetail?.city,
				type: CONTRACT_TYPE.CONTRACTED,
				state: response.customerDetail?.state,
				status: response.status,
				teamPIC: response.teamPic.id,
				salesOrderId: response.saleOrderId,
				workInspectionId: response.workInspection?.id,
				endDate: dayjs(response.endDate) || "",
				endTime: dayjs(response.endDate) || "",
				referenceNo: response.referenceNo,
				siteId: response.customerDetail?.customerContractSite.id,
				siteName: response.customerDetail?.customerContractSite.name,
				assetId: response.woAsset[0].id,
				postcode: response.customerDetail?.postcode,
				startDate: dayjs(response.startDate) || "",
				startTime: dayjs(response.startDate) || "",
				targetCompletionDate: dayjs(response.targetCompletionDate),
				targetCompletionTime: dayjs(response.targetCompletionDate),
				customerPic: response.customerDetail?.customerPicContact.picName,
				customerPicId: response.customerDetail?.customerPicContact.id,
				contractId: response.customerDetail.customerContract.id,
				overallJobDescription: response.overallJobDescription,
				issueDescription: response.issueDescription,
				buildingType: response.customerDetail?.configBuildingType?.id,
				addressLine1: response.customerDetail?.address_1,
				addressLine2: response.customerDetail?.address_2,
				email: response.customerDetail?.customerPicContact.email,
				prefixNo: response.customerDetail?.customerPicContact.mobileNoPrefix,
				mobileNo: response.customerDetail?.customerPicContact.mobileNo,
				scheduledDate: dayjs(response.scheduledDate),
				scheduledTime: dayjs(response.scheduledDate),
				customerId: response.customerDetail?.customer.id,
				registeredName: response.customerDetail?.customer.registeredName,
				woAsset: response.woAsset,
				preparedBy: { employeeId: response.signature?.preparedBy?.id, name: response.signature?.preparedBy?.employeeName, signature: response.signature?.preparedBySignaturePath },
				completedBy: { employeeId: response.signature?.completedBy?.id, name: response.signature?.completedBy?.employeeName, signature: response.signature?.completedBySignaturePath },
				verifiedBy: { employeeId: response.signature?.verifiedBy?.id, name: response.signature?.verifiedBy?.employeeName, signature: response.signature?.verifiedBySignaturePath },
				acknowledgedBy: { employeeId: response.signature?.acknowledgedBy?.id, name: response.signature?.acknowledgedBy?.employeeName, signature: response.signature?.acknowledgedBySignaturePath },
				rescheduledBy: { employeeId: response.signature?.rescheduledBy?.id, name: response.signature?.rescheduledBy?.employeeName, signature: response.signature?.rescheduledBySignaturePath },
				cancelledBy: { employeeId: response.signature?.cancelledBy?.id, name: response.signature?.cancelledBy?.employeeName, signature: response.signature?.cancelledBySignaturePath },
				pendingRescheduledBy: { employeeId: response?.signature?.pendingRescheduledBy?.id, name: response.signature?.pendingRescheduledBy?.employeeName, signature: response.signature?.pendingRescheduledBySignaturePath }
			});

			props.onHandleSetWoInfo({ referenceNo: response.referenceNo, employeeCustomerId: response.customerDetail?.customer.id, status: response.status, siteName: response.customerDetail?.customerContractSite.name, assetId: response.woAsset[0].id, customerContractSiteAssetId: response.woAsset[0].customerContractSiteAssetId, lastModifiedBy: response.lastModifiedBy, lastModifiedDate: response.lastModifiedDate });
		}
	}, [memoSetFormValues, props]);

	const onHandleGetRelevantSignature = useCallback(() => {
		let signatureObject = {};

		switch (signatureType) {
			case SIGNATURE_TYPE.PREPARED_BY:
				signatureObject = formik.values.preparedBy;
				break;
			case SIGNATURE_TYPE.ACKNOWLEDGED_BY:
				signatureObject = formik.values.acknowledgedBy;
				break;
			case SIGNATURE_TYPE.RESCHEDULED_BY:
				signatureObject = formik.values.rescheduledBy;
				break;
			case SIGNATURE_TYPE.CANCELLED_BY:
				signatureObject = formik.values.cancelledBy;
				break;
			default:
				break;
		}

		return signatureObject;
	}, [formik.values, signatureType]);

	//prettier-ignore
	const onHandleSignatures = useCallback(async (workInspectionId) => {
		let response = null;

		if (!signatureType) return;

		const signatureObj = onHandleGetRelevantSignature();

		try {
			const formData = new FormData();

			formData.append("file", signatureObj.signatureFile);
			formData.append("woId", workInspectionId);
			formData.append("employeeCustomerId", signatureObj.employeeId);
			formData.append("signatureType", signatureType);

			await api.post.workOrder.signatureUpload(formData);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Signature was added successfully!" }));
			}

			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Signature was updated successfully!" }));
			}
		}
	}, [dispatch, isCreate, onHandleGetRelevantSignature, signatureType]);

	//prettier-ignore
	const onHandleUpdateTeamMembersLocal = useCallback((teamMembers) => {
		formik.setFieldValue("teamMembers", teamMembers);
	}, [formik]);

	//prettier-ignore
	const onHandleUpdateTeamMembers = useCallback(async (workOrderId, teamMembers) => {
		let response = null;

		try {
			const employeeIds = teamMembers.map((o) => o.id);

			const payload = {
				workOrderId: workOrderId,
				employeeIds: employeeIds
			};

			await api.post.workOrder.addTeamMembers(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Team members were added successfully!" }));
			}

			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Team members were updated successfully!" }));
			}

			teamMembersTableRef.current?.onHandleGetList();
		}
	}, [dispatch, isCreate]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = {
				workOrderType: orderType,
				contractType: values.type,
				scheduledDate: combineDateTime(values.scheduledDate, values.scheduledTime),
				targetCompletionDate: combineDateTime(values.targetCompletionDate, values.targetCompletionTime),
				teamPicId: values.teamPIC,
				overallJobDescription: values.overallJobDescription,
				issueDescription: values.issueDescription,
				woAsset: values.woAsset,
				customerDetail: {
					customerId: values.customerId,
					customerPicContactId: values.customerPicId,
					customerContractId: values.contractId,
					customerContractSiteName: values.siteName,
					customerContractSiteId: values.siteId,
					configBuildingTypeId: values.buildingType,
					address_1: values.addressLine1,
					address_2: values.addressLine2,
					state: values.state,
					city: values.city,
					postcode: values.postcode
				}
			};

			if (isCreate) {
				response = await api.post.workOrder.createWorkOrderReport(payload);
			}

			if (!isCreate) {
				payload.id = values.id;
				payload.referenceNo = values.referenceNo;
				payload.status = values.status;

				response = await api.post.workOrder.updateWorkOrderReport(payload);
			}
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Work Inspection Report was added successfully!" }));
			}

			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Work Inspection Report was updated successfully!" }));
			}

			onHandleSignatures(response.id);

			if (formik.values.teamMembers?.length) {
				onHandleUpdateTeamMembers(response.id, formik.values.teamMembers);
			}

			navigate(pathnames.workOrder.workOrder + orderType);
		}
	}, [orderType, isCreate, formik, onHandleSignatures, navigate, dispatch, onHandleUpdateTeamMembers]);

	const onHandleOnHoldModal = useCallback(() => {
		holdOnModal.current.onHandleShow({ status: PAGE.CREATE });
	}, []);

	const onHandleGetWIRData = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.workInspectionReport.reportLite({ "work-inspection-id": formik.values.workInspectionId });
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			let nextValues = structuredClone(formik.values);

			formik.setValues({ ...nextValues, siteId: response.customerContractSite.id, buildingType: response.configBuildingType.id, addressLine1: response.address1, addressLine2: response.address2, state: response.state, city: response.region, postcode: response.postcode });
		}
	}, [formik]);

	//prettier-ignore
	const onHandleWorkInspectionCheckbox = useCallback((boolean) => {
		let nextValues = structuredClone(formik.values);

		if (!boolean) {
			nextValues = { ...nextValues, customerId: "", customerPicId: "", contractId: "", siteName: "", assetId: "", customerAssetName: "", email: "", mobileNo: "", mobileNoPrefix: "", buildingType: "", workSpace: "", state: "", city: "", postcode: "", addressLine1: "", addressLine2: "" };
		
			formik.setValues(nextValues);
		} else {
			onHandleGetWIRData();
		}

		formik.setFieldValue("workInspectionCheckbox", boolean);
	}, [formik, onHandleGetWIRData]);

	//prettier-ignore
	const onHandleSelectCustomerPic = useCallback(async (event) => {
		const value = event.target.value;

		let customerPic = {};

		try {
			const customerPicListing = await getCustomerPicListing({ id: formik.values.customerId, keyword: value });

			customerPic = customerPicListing.content[0];
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		let nextValues = structuredClone(formik.values);

		formik.setValues({ ...nextValues, customerPic: customerPic.label, customerPicId: customerPic?.id, email: customerPic?.email, prefixNo: customerPic?.mobileNoPrefix, mobileNo: customerPic?.mobileNo });
	}, [formik]);

	// prettier-ignore
	const onHandleSelectSiteName = useCallback(async (event) => {
		const value = event.target.value;

		let response = null;

		try {
			response = await api.get.customerSiteMaintenance.site(value);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			let nextValues = structuredClone(formik.values);

			formik.setValues({ ...nextValues, siteId: value, buildingType: response.configBuildingTypeId, addressLine1: response.address1, addressLine2: response.address2, state: response.state, city: response.region, postcode: response.postcode });
		}
	}, [formik]);

	//prettier-ignore
	const onHandleSelectSiteAsset = useCallback(async (event) => {
		const value = event.target.value;

		let response = null;

		try {
			const params = { page: 0, size: 10, "customer-contract-site-id": formik.values.siteId };

			response = await api.get.customerSiteMaintenance.assets(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			let nextValues = structuredClone(formik.values);

			const customerAssetName = response.content[0].customerAssetName;

			const workSpace = await getWorkSpaceListing({ keyword: response.content[0].workspace });

			const asset = await getAssetTypeListing({ keyword: response.content[0].assetType });

			const woAsset = [{ id: formik.values.woAsset[0]?.id || "", customerContractSiteAssetId: value, customerAssetName: customerAssetName, assetName: response.content[0].assetName, configAssetTypeId: asset.content[0].value, configWorkspaceId: workSpace.content[0].value }];

			formik.setValues({ ...nextValues, assetId: value, customerAssetName: customerAssetName, workSpaceId: workSpace.content[0].value, woAsset: woAsset });
		}
	}, [formik]);

	const getRegionLoadOptions = useCallback(() => getRegionListing({ state: formik.values.state }), [formik.values.state]);
	const getPostcodeLoadOptions = useCallback(() => getPostcodeListing(`${formik.values.state}/${formik.values.city}`), [formik.values.state, formik.values.city]);

	//prettier-ignore
	const onHandleSetSignature = useCallback((name, value, signatureType) => {
		setSignatureType(signatureType);

		let image = "";
		let signatureName = `${name.split(".")[0]}.signatureFile`;

		if (value) {
			image = URL.createObjectURL(value);
		}

		formik.setFieldValue(name, image);
		formik.setFieldValue(signatureName, value);
	}, [formik]);

	useEffect(() => {
		if (!isCreate) onHandleGetDetails(id);
	}, [id, isCreate, onHandleGetDetails]);

	useEffect(() => {
		return () => {
			if (!isCreate) cancelRequest(ENDPOINT_PATH.WORK_ORDER.WORK_ORDER);
		};
	}, [cancelRequest, isCreate]);

	return (
		<div className="app-work-order-site-details">
			<div className="work-order-site-details">
				<form className="work-order-site-details__form" onSubmit={formik.handleSubmit}>
					<div className="work-order-site-details__container">
						<p className="work-order-site-details__label">General Details</p>

						{!isCreate && (
							<div className="work-order-site-details__row">
								<AppInput disabled type="text" name="referenceNo" label="Work Order ID" placeholder="Work Order ID" value={formik.values.referenceNo} error={formik.errors.referenceNo} touched={formik.touched.referenceNo} onChange={formik.handleChange} />

								<AppSelectInput disabled name="status" label="Status" placeholder="Select..." options={statusOptions} value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />
							</div>
						)}

						<div className="work-order-site-details__row">
							<AppRadioInput required label="Type" options={contractTypeOptions} value={formik.values.type} error={formik.errors.type} touched={formik.touched.type} onChange={(v) => formik.setFieldValue("type", v)} />

							<AppSelectInput name="workInspectionId" label="Work Inspection ID" placeholder="Enter Work Inspection ID" loadOptions={getWorkInspectionListing} value={formik.values.workInspectionId} error={formik.errors.workInspectionId} touched={formik.touched.workInspectionId} onChange={formik.handleChange} />
						</div>

						<div className="work-order-site-details__row">
							<AppSelectInput disabled name="salesOrderId" label="Sales Order ID" placeholder="Enter Sales Order ID" loadOptions={getSalesOrderListing} value={formik.values.salesOrderId} error={formik.errors.salesOrderId} touched={formik.touched.salesOrderId} onChange={formik.handleChange} />

							<AppSelectInput disabled name="workCompletionId" label="Work Completion ID" placeholder="Enter Work Completion ID" options={[]} value={formik.values.workCompletionId} error={formik.errors.workCompletionId} touched={formik.touched.workCompletionId} onChange={formik.handleChange} />
						</div>

						<AppInput required type="text" name="overallJobDescription" label="Overall Job Description" placeholder="Overall Job Description" value={formik.values.overallJobDescription} error={formik.errors.overallJobDescription} touched={formik.touched.overallJobDescription} onChange={formik.handleChange} />

						<AppInput required multiline type="textarea" name="issueDescription" label="Issue Description" placeholder="Issue Description" value={formik.values.issueDescription} error={formik.errors.issueDescription} touched={formik.touched.issueDescription} onChange={formik.handleChange} />

						{formik.values.status !== STATUS.DRAFT && (
							<div className="work-order-site-details__row">
								<AppInput disabled type="text" name="preparedDate" label="Prepared Date" placeholder="" value={formik.values.preparedDate} error={formik.errors.preparedDate} touched={formik.touched.preparedDate} onChange={formik.handleChange} />
							</div>
						)}

						<p className="work-order-site-details__label">Customer Details</p>

						<div className="work-order-site-details__row work-order-site-details__row--checkbox">
							<div className="work-order-site-details__column work-order-site-details__column--checkbox">
								<AppSelectInput required pagination name="customerId" label="Registered Name" placeholder="Select..." loadOptions={getCustomerLookupListing} value={formik.values.customerId} error={formik.errors.customerId} touched={formik.touched.customerId} onChange={formik.handleChange} />

								<AppCheckbox disabled={checkBoxDisable} name="workInspectionCheckbox" label="Same as Work Inspection Customer" value={formik.values.workInspectionCheckbox} touched={formik.touched.workInspectionCheckbox} onClick={(e) => onHandleWorkInspectionCheckbox(e)} />
							</div>

							<AppSelectInput disabled={restricted || !formik.values.customerId} required pagination name="customerPic" label="Customer PIC" placeholder="Select..." loadOptions={() => getCustomerPicListing({ id: formik.values.customerId })} value={formik.values.customerPic} error={formik.errors.customerPic} touched={formik.touched.customerPic} onChange={onHandleSelectCustomerPic} />
						</div>

						<div className="work-order-site-details__row">
							{isContract && <AppSelectInput disabled={restricted || !formik.values.customerPic} name="contractId" label="Contract ID" placeholder="Select..." loadOptions={() => getContractByPicListing({ id: formik.values.customerPicId })} value={formik.values.contractId} error={formik.errors.contractId} touched={formik.touched.contractId} onChange={formik.handleChange} />}

							{isContract && <AppSelectInput required disabled={restricted || !formik.values.contractId} name="siteId" label="Site Name" placeholder="Select..." loadOptions={() => getContractSitesListing({ id: formik.values.contractId })} value={formik.values.siteId} error={formik.errors.siteId} touched={formik.touched.siteId} onChange={onHandleSelectSiteName} />}

							{!isContract && <AppInput type="text" name="siteName" label="Site Name" placeholder="Enter Site Name" value={formik.values.siteName} touched={formik.touched.siteName} onChange={formik.handleChange} />}
						</div>

						<div className="work-order-site-details__row">
							<AppSelectInput required disabled={contractCondition || !isCreate} name="assetId" label="Asset Name" placeholder="Asset Name" loadOptions={() => getSiteAssetListing({ id: formik.values.siteId })} value={formik.values.assetId} error={formik.errors.assetId} touched={formik.touched.assetId} onChange={onHandleSelectSiteAsset} />

							<AppInput disabled={isContract} type="text" name="customerAssetName" label="Customer Asset Name" placeholder="Customer Asset Name" value={formik.values.customerAssetName} error={formik.errors.customerAssetName} touched={formik.touched.customerAssetName} onChange={formik.handleChange} />
						</div>

						<div className="work-order-site-details__row">
							<AppMobileInput disabled type="number" name="mobileNo" label="Mobile No." value={formik.values.mobileNo} prefixNo={formik.values.prefixNo} error={formik.errors.mobileNo} touched={formik.touched.mobileNo} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} />

							<AppInput disabled type="text" name="email" label="Email" placeholder="Email" value={formik.values.email} error={formik.errors.email} touched={formik.touched.email} onChange={formik.handleChange} />
						</div>

						<p className="work-order-site-details__label">Site Location</p>

						<div className="work-order-site-details__row">
							<AppSelectInput disabled={restricted || isContract} name="buildingType" label="Building Type" placeholder="Building Type" loadOptions={getBuildingTypeListing} value={formik.values.buildingType} touched={formik.touched.buildingType} onChange={formik.handleChange} />

							<AppSelectInput disabled={restricted || isContract} name="workSpaceId" label="Work Space" placeholder="Work Space" loadOptions={getWorkSpaceListing} value={formik.values.workSpaceId} error={formik.errors.workSpaceId} touched={formik.touched.workSpaceId} onChange={formik.handleChange} />
						</div>

						<div className="work-order-site-details__row">
							<AppInput disabled={restricted || isContract} type="text" name="addressLine1" label="Address Line 1" placeholder="Address Line 1" value={formik.values.addressLine1} error={formik.errors.addressLine1} touched={formik.touched.addressLine1} onChange={formik.handleChange} />

							<AppInput disabled={restricted || isContract} type="text" name="addressLine2" label="Address Line 2" placeholder="Address Line 2" value={formik.values.addressLine2} error={formik.errors.addressLine2} touched={formik.touched.addressLine2} onChange={formik.handleChange} />
						</div>

						<div className="work-order-site-details__row">
							<AppSelectInput disabled={restricted || isContract} name="state" label="State" placeholder="State" loadOptions={getStateListing} value={formik.values.state} touched={formik.touched.state} onChange={formik.handleChange} />

							<AppSelectInput disabled={restricted || !formik.values.state || isContract} name="city" label="City" placeholder="City" loadOptions={getRegionLoadOptions} value={formik.values.city} touched={formik.touched.city} onChange={formik.handleChange} />
						</div>

						<div className="work-order-site-details__row">
							<AppSelectInput disabled={restricted || !formik.values.city || isContract} name="postcode" label="Postcode" placeholder="Postcode" loadOptions={getPostcodeLoadOptions} value={formik.values.postcode} touched={formik.touched.postcode} onChange={formik.handleChange} />
						</div>
					</div>

					<div className="work-order-site-details__container">
						<p className="work-order-site-details__label">Scheduling</p>

						<div className="work-order-site-details__row">
							<AppInputDate required name="scheduledDate" label="Scheduled Date" placeholder="DD/MM/YYYY" value={formik.values.scheduledDate} error={formik.errors.scheduledDate} touched={formik.touched.scheduledDate} onChange={formik.setFieldValue} />

							<AppInputDate required name="targetCompletionDate" label="Target Completion Date" placeholder="DD/MM/YYYY" value={formik.values.targetCompletionDate} error={formik.errors.targetCompletionDate} touched={formik.touched.targetCompletionDate} onChange={formik.setFieldValue} />
						</div>

						<div className="work-order-site-details__row">
							<AppInputTime required name="scheduledTime" label="Scheduled Time" placeholder="HH:MM" value={formik.values.scheduledTime} error={formik.errors.scheduledTime} touched={formik.touched.scheduledTime} onChange={formik.setFieldValue} />

							<AppInputTime required name="targetCompletionTime" label="Target Scheduled Time" placeholder="HH:MM" value={formik.values.targetCompletionTime} error={formik.errors.targetCompletionTime} touched={formik.touched.targetCompletionTime} onChange={formik.setFieldValue} />
						</div>

						<div className="work-order-site-details__row">
							<AppSelectInput pagination name="teamPIC" label="Team PIC" placeholder="Select..." loadOptions={getEmployeeMaintenanceListing} value={formik.values.teamPIC} touched={formik.touched.teamPIC} onChange={formik.handleChange} />
						</div>

						<div className="work-order-site-details__row work-order-site-details__row--divider">
							<AppSiteDetailsTeamMembersTable ref={teamMembersTableRef} teamMembersData={formik.values.teamMembers} onHandleUpdateTeamMembersLocal={onHandleUpdateTeamMembersLocal} onHandleUpdateTeamMembers={onHandleUpdateTeamMembers} />
						</div>

						<p className="work-order-site-details__label">Duration Details</p>

						<div className="work-order-site-details__row">
							<AppInputDate disabled name="startDate" label="Start Date" placeholder="DD/MM/YYYY" value={formik.values.startDate} error={formik.errors.startDate} touched={formik.touched.startDate} onChange={formik.setFieldValue} />

							<AppInputDate disabled name="endDate" label="End Date" placeholder="DD/MM/YYYY" value={formik.values.endDate} error={formik.errors.endDate} touched={formik.touched.endDate} onChange={formik.setFieldValue} />
						</div>

						<div className="work-order-site-details__row">
							<AppInputTime disabled name="startTime" label="Start Time" placeholder="HH:MM" value={formik.values.startTime} error={formik.errors.startTime} touched={formik.touched.startTime} onChange={formik.setFieldValue} />

							<AppInputTime disabled name="endTime" label="End Time" placeholder="HH:MM" value={formik.values.endTime} error={formik.errors.endTime} touched={formik.touched.endTime} onChange={formik.setFieldValue} />
						</div>

						<div className="work-order-site-details__row work-order-site-details__row--divider">
							<AppInput disabled type="text" name="duration" label="Duration" placeholder="Duration" value={formik.values.duration} error={formik.errors.duration} touched={formik.touched.duration} onChange={formik.handleChange} />
						</div>
					</div>

					<div className="work-order-site-details__container">
						<div className="work-order-site-details__row work-order-site-details__row--signature">
							<div className="work-order-site-details__column">
								<Fragment>
									<AppSelectInput name="preparedBy.employeeId" label="Prepared By" placeholder="Search by User ID or Name" loadOptions={getEmployeeMaintenanceListing} value={formik.values.preparedBy?.employeeId} error={formik.errors.preparedBy?.employeeId} touched={formik.touched.preparedBy?.employeeId} onChange={formik.handleChange} />

									<AppInputDragAndDrop name="preparedBy.signature" accept="image/png, image/jpeg, image/jpg" value={formik.values.preparedBy?.signature} error={formik.errors.preparedBy?.signature} touched={formik.touched.preparedBy?.signature} onChange={(name, file) => onHandleSetSignature(name, file, SIGNATURE_TYPE.PREPARED_BY)} />
								</Fragment>
							</div>

							<div className="work-order-site-details__column">
								<Fragment>
									<AppSelectInput name="completedBy.employeeId" label="Completed By" placeholder="Search by User ID or Name" loadOptions={getEmployeeMaintenanceListing} value={formik.values.completedBy?.employeeId} error={formik.errors.completedBy?.employeeId} touched={formik.touched.completedBy?.employeeId} onChange={formik.handleChange} />

									<AppInputDragAndDrop name="completedBy.signature" accept="image/png, image/jpeg, image/jpg" value={formik.values.completedBy?.signature} error={formik.errors.completedBy?.signature} touched={formik.touched.completedBy?.signature} onChange={(name, file) => onHandleSetSignature(name, file, SIGNATURE_TYPE.COMPLETED_BY)} />
								</Fragment>
							</div>
						</div>

						<div className="work-order-site-details__row work-order-site-details__row--signature">
							<div className="work-order-site-details__column">
								<Fragment>
									<AppSelectInput name="acknowledgedBy.employeeId" label="Acknowledged By" placeholder="Search by User ID or Name" loadOptions={getEmployeeMaintenanceListing} value={formik.values.acknowledgedBy?.employeeId} error={formik.errors.acknowledgedBy?.employeeId} touched={formik.touched.acknowledgedBy?.employeeId} onChange={formik.handleChange} />

									<AppInputDragAndDrop name="acknowledgedBy.signature" accept="image/png, image/jpeg, image/jpg" value={formik.values.acknowledgedBy?.signature} error={formik.errors.acknowledgedBy?.signature} touched={formik.touched.acknowledgedBy?.signature} onChange={(name, file) => onHandleSetSignature(name, file, SIGNATURE_TYPE.ACKNOWLEDGED_BY)} />
								</Fragment>
							</div>

							<div className="work-order-site-details__column">
								<Fragment>
									<AppSelectInput name="verifiedBy.employeeId" label="Acknowledged By" placeholder="Search by User ID or Name" loadOptions={getEmployeeMaintenanceListing} value={formik.values.verifiedBy?.employeeId} error={formik.errors.verifiedBy?.employeeId} touched={formik.touched.verifiedBy?.employeeId} onChange={formik.handleChange} />

									<AppInputDragAndDrop name="verifiedBy.signature" accept="image/png, image/jpeg, image/jpg" value={formik.values.verifiedBy?.signature} error={formik.errors.verifiedBy?.signature} touched={formik.touched.verifiedBy?.signature} onChange={(name, file) => onHandleSetSignature(name, file, SIGNATURE_TYPE.ACKNOWLEDGED_BY)} />
								</Fragment>
							</div>
						</div>

						<div className="work-order-site-details__row work-order-site-details__row--signature">
							<div className="work-order-site-details__column">
								<Fragment>
									<AppInput disabled type="text" name="pendingRescheduledBy.name" label="Pending Rescheduled By" placeholder="Pending Rescheduled By" value={formik.values.pendingRescheduledBy?.name} error={formik.errors.pendingRescheduledBy?.name} touched={formik.touched.pendingRescheduledBy?.name} onChange={formik.handleChange} />

									<AppInputDragAndDrop name="pendingRescheduledBy.signature" accept="image/png, image/jpeg, image/jpg" value={formik.values.pendingRescheduledBy?.signature} error={formik.errors.pendingRescheduledBy?.signature} touched={formik.touched.pendingRescheduledBy?.signature} onChange={(name, file) => onHandleSetSignature(name, file, SIGNATURE_TYPE.PENDING_RESCHEDULED_BY)} />
								</Fragment>
							</div>

							<div className="work-order-site-details__column">
								<Fragment>
									<AppSelectInput name="rescheduledBy.employeeId" label="Rescheduled By" placeholder="Search by User ID or Name" loadOptions={getEmployeeMaintenanceListing} value={formik.values.rescheduledBy?.employeeId} error={formik.errors.rescheduledBy?.employeeId} touched={formik.touched.rescheduledBy?.employeeId} onChange={formik.handleChange} />

									<AppInputDragAndDrop name="rescheduledBy.signature" accept="image/png, image/jpeg, image/jpg" value={formik.values.rescheduledBy?.signature} error={formik.errors.rescheduledBy?.signature} touched={formik.touched.rescheduledBy?.signature} onChange={(name, file) => onHandleSetSignature(name, file, SIGNATURE_TYPE.RESCHEDULED_BY)} />
								</Fragment>
							</div>

							<div className="work-order-site-details__column">
								<AppInput multiline type="textarea" name="reasonForReschedule" label="Reason for Reschedule" placeholder="Reason for Reschedule" value={formik.values.reasonForReschedule} error={formik.errors.reasonForReschedule} touched={formik.touched.reasonForReschedule} onChange={formik.handleChange} />
							</div>
						</div>
					</div>

					<div className="work-order-site-details__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleOnHoldModal} />

						<AppButton type="submit" label={submitLabel} />
					</div>
				</form>
			</div>

			<AppWorkOrderHoldOnModal ref={holdOnModal} />
		</div>
	);
};

export default AppWorkOrderSiteDetails;

AppWorkOrderSiteDetails.propTypes = {
	values: PropTypes.object,
	onHandleTabSubmit: PropTypes.func
};
