import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import Menu from "@mui/material/Menu";
import { useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import ENDPOINT_PATH from "constants/end-point-path";

import AppButton from "components/app-button";
import AppTable from "components/app-table/app-table";
import AppWorkOrderInventoryRemoveModal from "components/pages/work-order/app-work-order-inventory-remove-modal";
import AppWorkOrderCreateEditInventoryModal from "components/pages/work-order/app-work-order-create-edit-inventory-modal";

import editIcon from "assets/images/edit-icon.png";
import deleteIcon from "assets/images/trash-icon.png";
import addBlueIcon from "assets/images/add-blue-icon.png";
import moreIcon from "assets/images/vertical-breadcrumbs-icon.png";

const AppWorkOrderInventory = () => {
	const { id } = useParams();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const inventoryRef = useRef();
	const inventoryRemoveRef = useRef();
	const [data, setData] = useState(convertPaginationTableData());
	const [selectedInventory, setSelectedInventory] = useState(null);
	const [inventoryTableAnchor, setInventoryTableAnchor] = useState(null);

	const onHandleAddInventory = useCallback(() => {
		if (inventoryRef.current) {
			inventoryRef.current.onHandleShow();
		}
	}, []);

	const onHandleCloseInventoryTableMenu = useCallback(() => {
		setInventoryTableAnchor(null);
	}, []);

	const onHandleEditInventory = useCallback(() => {
		if (inventoryRef.current) {
			inventoryRef.current.onHandleShow(selectedInventory);

			onHandleCloseInventoryTableMenu();
		}
	}, [onHandleCloseInventoryTableMenu, selectedInventory]);

	//prettier-ignore
	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ page: 0, size: 10 });

			response = await api.get.workOrder.inventories({ ...params, "work-order-id": id });
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, [id]);

	const onHandleDeleteInventory = useCallback(() => {
		inventoryRemoveRef.current.onHandleShow(selectedInventory);

		onHandleCloseInventoryTableMenu();
	}, [onHandleCloseInventoryTableMenu, selectedInventory]);

	//prettier-ignore
	const onToggleTableAction = useCallback((event, tableMeta) => {
		setSelectedInventory(data.content[tableMeta.rowIndex]);
		setInventoryTableAnchor(event.currentTarget);
	}, [data.content]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false
			}
		},
		{
			name: "id",
			label: "Inventory ID",
			options: {
				sort: false
			}
		},
		{
			name: "inventoryItem",
			label: "Inventory Item",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "inventoryLocationName",
			label: "Location",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "quantity",
			label: "Quantity Needed",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "edit",
			label: "Action",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={(event) => onToggleTableAction(event, tableMeta)}>
							<img src={moreIcon} alt="more-icon" />
						</button>
					);
				}
			}
		}
	], [onToggleTableAction]);

	const emptyState = useMemo(() => {
		if (data.content.length) return {};

		const node = () => (
			<tbody>
				<tr className="table__empty-state">
					<td colSpan={tableColumns.length} align="center">
						<p className="table__text">
							No Inventory Found.
							<span className="table__link" onClick={onHandleAddInventory}>
								Add Inventory?
							</span>
						</p>
					</td>
				</tr>
			</tbody>
		);

		return { TableBody: node };
	}, [data.content, onHandleAddInventory, tableColumns.length]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList, cancelRequest]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.WORK_ORDER.INVENTORIES);
		};
	}, [cancelRequest]);

	return (
		<div className="app-work-order-inventory">
			<div className="work-order-inventory">
				<div className="work-order-inventory__container">
					<div className="work-order-inventory__header">
						<p className="work-order-inventory__label">Inventory</p>

						<div className="work-order-inventory__add-button">
							<AppButton outline type="button" label="Add Inventory" icon={addBlueIcon} onClick={onHandleAddInventory} />
						</div>
					</div>

					<AppTable data={data.content} columns={tableColumns} components={emptyState} />
				</div>

				{/*prettier-ignore*/}
				<Menu classes={{ root: "work-order-inventory-table-menu" }} anchorEl={inventoryTableAnchor} open={!!inventoryTableAnchor} onClose={onHandleCloseInventoryTableMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
					<MenuItem onClick={onHandleEditInventory}><img src={editIcon} alt="inventory-edit" />Edit</MenuItem>

					<MenuItem onClick={onHandleDeleteInventory}><img src={deleteIcon} alt="inventory-close" />Delete</MenuItem>
				</Menu>

				<AppWorkOrderInventoryRemoveModal ref={inventoryRemoveRef} onHandleGetList={onHandleGetList} />

				<AppWorkOrderCreateEditInventoryModal ref={inventoryRef} onHandleGetList={onHandleGetList} />
			</div>
		</div>
	);
};

export default AppWorkOrderInventory;
