import React, { useCallback, useMemo, useState } from "react";

import Menu from "@mui/material/Menu";
import { useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";

import pathnames from "routes/pathnames";

import api from "services/api";

import useBreadcrumb from "hooks/use-breadcrumb";

import serveRequestErrors from "common/serve-request-errors";

import AppTabs from "components/app-tabs";
import AppButton from "components/app-button";
import AppSalesOrderDetails from "components/pages/sales/app-sales-order-details";
import AppSalesOrderInvoice from "components/pages/sales/app-sales-order-invoice";
import AppSalesOrderDeliveryOrder from "components/pages/sales/app-sales-order-delivery-order";

import exportIcon from "assets/images/export-icon.png";
import detailsIcon from "assets/images/pages/sales/details-icon.svg";
import invoiceIcon from "assets/images/pages/sales/invoice-icon.svg";
import chevronIcon from "assets/images/chevron-right-light-blue-icon.svg";
import deliveryOrderIcon from "assets/images/pages/sales/delivery-order-icon.svg";
import activeDetailsIcon from "assets/images/pages/sales/details-active-icon.svg";
import activeInvoiceIcon from "assets/images/pages/sales/invoice-active-icon.svg";
import activeDeliveryOrderIcon from "assets/images/pages/sales/delivery-order-active-icon.svg";

const PageSalesOrderCreateEdit = () => {
	const { id } = useParams();
	const [menuAnchor, setMenuAnchor] = useState(null);

	//prettier-ignore
	const salesOrderTabs = useMemo(() => [
		{ label: "Details", icon: detailsIcon, activeIcon: activeDetailsIcon, name: "DETAILS", component: <AppSalesOrderDetails />, accessible: true },
		{ label: "Delivery Order", icon: deliveryOrderIcon, activeIcon: activeDeliveryOrderIcon, name: "DELIVERY_ORDER", component: <AppSalesOrderDeliveryOrder />, accessible: true },
		{ label: "Invoices", icon: invoiceIcon, activeIcon: activeInvoiceIcon, name: "INVOICE", component: <AppSalesOrderInvoice />, accessible: true },
	], []);

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Sales", path: pathnames.sales.quotations },
			{ label: "Sales Order", path: pathnames.sales.salesOrders },
			{ label: "Edit Sales Order", path: pathnames.sales.salesOrderCreateEdit }
		];

		return data;
	}, []);

	useBreadcrumb({ breadCrumb });

	const onHandleSetComplete = () => {
		setMenuAnchor(null);
	};

	const onHandleSetDropOff = () => {
		setMenuAnchor(null);
	};

	const onHandleCloseMenu = useCallback(() => {
		setMenuAnchor(null);
	}, []);

	const onToggleFilterMenu = useCallback((event) => {
		setMenuAnchor(event.currentTarget);
	}, []);

	const onHandleExportPDF = useCallback(async () => {
		let response = null;
		let fileName = "";

		try {
			const payload = { "sale-order-id": id };

			const transformResponse = (data, headers) => {
				fileName = headers?.["content-disposition"]?.split("inline; filename=")?.[1]?.split(";")?.[0];

				if (fileName) return data;

				try {
					const jsonResponse = JSON.parse(new TextDecoder().decode(data));

					if (jsonResponse) return jsonResponse;
				} catch (error) {
					return data;
				}
			};

			response = await api.post.salesOrders.generateSalesOrderPdf(payload, { transformResponse });
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			const a = document.createElement("a");
			document.body.appendChild(a);
			const url = window.URL.createObjectURL(new Blob([response]), { type: "application/octet-stream" });
			a.href = url;
			a.download = fileName;
			a.click();

			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
			}, 1000);
		}
	}, [id]);

	return (
		<div className="page-sales-order-create-edit">
			<div className="sales-order-create-edit">
				<div className="sales-order-create-edit__header">
					<h1 className="sales-order-create-edit__title">Edit Sales Order</h1>

					<div className="sales-order-create-edit__header sales-order-create-edit__header--column">
						<div className="sales-order-create-edit__header-buttons">
							<AppButton outline className="sales-order-create-editt__action-button" type="button" label="Actions" icon={chevronIcon} onClick={onToggleFilterMenu} />

							<AppButton outline type="button" label="Export as PDF" icon={exportIcon} onClick={onHandleExportPDF} />
						</div>

						<p className="sales-order-create-edit__last-update">
							<span>Last Updated By</span> Ahmad Muhammad Ali, 03/11/2023, 10:00 am
						</p>
					</div>
				</div>

				<div className="sales-order-create-edits__body">
					<AppTabs tabs={salesOrderTabs} />
				</div>
			</div>

			<Menu classes={{ root: "sales-order-create-edit-menu" }} anchorEl={menuAnchor} open={!!menuAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem onClick={onHandleSetComplete}>Set As Complete with Exception</MenuItem>

				<MenuItem onClick={onHandleSetDropOff}>Set As Drop Off</MenuItem>
			</Menu>
		</div>
	);
};

export default PageSalesOrderCreateEdit;
