import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import ENDPOINT_PATH from "constants/end-point-path";

import AppTable from "components/app-table/app-table";

import chevronRightIcon from "assets/images/chevron-right-icon.png";

const AppWorkOrder = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [data, setData] = useState(convertPaginationTableData());
	const queryParams = useRef({ page: 0, keyword: "", sort: "" });

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			response = await api.get.workInspectionReport.workOrderReports({ ...params, "work-inspection-id": id });
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, [id]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false
			}
		},
		{
			name: "woAsset",
			label: "Asset Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "customerAssetName",
			label: "Customer Asset Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "referenceNo",
			label: "Work Order ID",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "type",
			label: "Type",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowData = data.content[tableMeta.rowIndex];
					const type = rowData.type;
					const workOrderId = rowData.id;

					return (
						<button type="button" className="table__edit" onClick={() => navigate(`${pathnames.workOrder.workOrderCreateEdit}/${type}/${workOrderId}`)}>
							<img src={chevronRightIcon} alt="edit-icon" />
						</button>
					);
				}
			}
		}
	], [data.content, navigate]);

	// prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.WORK_INSPECTION_REPORT.WORK_ORDER_REPORTS);
		};
	}, [cancelRequest]);

	return (
		<div className="app-work-order">
			<div className="work-order">
				<div className="work-order__container">
					<h1 className="work-order__label">Work Orders</h1>

					<div className="work-order__body">
						<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default AppWorkOrder;
