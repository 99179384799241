import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";

import classNames from "common/class-names";
import getTotalDays from "common/get-total-days";
import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppInputDate from "components/app-input-date";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";

import editIcon from "assets/images/edit-icon.png";

const PageWorkInspectionListing = (props) => {
	const navigate = useNavigate();
	const [data, setData] = useState(convertPaginationTableData());
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const profile = useSelector((state) => state.profile);
	const memoCancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.ROLE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const defaultStatus = useMemo(() => [STATUS.DRAFT, STATUS.PENDING_ASSIGN, STATUS.PENDING_ASSET, STATUS.OPEN, STATUS.IN_PROGRESS, STATUS.PENDING_ACKNOWLEDGE, STATUS.PENDING_VERIFY, STATUS.VERIFIED, STATUS.COMPLETED, STATUS.CANCELLED, STATUS.OVERDUE, STATUS.PENDING_RESCHEDULE, STATUS.RESCHEDULED].join(","), []);
	const status = useMemo(() => {
		const currentStatuses = searchParams.get("status")?.split(",");
		const relevantStatus = currentStatuses?.every((e) => defaultStatus.split(",").includes(e));

		if (!relevantStatus) {
			return defaultStatus;
		} else {
			return searchParams.get("status");
		}
	}, [defaultStatus, searchParams]);
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", "start-date": searchParams.get("start-date") || "", "end-date": searchParams.get("end-date") || "", status: status, sort: searchParams.get("sort") || "" });

	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10 });

			response = await api.get.workInspectionReport.reports(params);

			memoSearchParams.current(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleSubmit = useCallback((values) => {
			queryParams.current.status = values.status.join(",");

			if (values.startDate && dayjs(values.startDate).isValid()) {
				queryParams.current["start-date"] = dayjs(values.startDate).format(DATE_TIME.YYYY_MM_DD);
			}

			if (values.endDate && dayjs(values.endDate).isValid()) {
				queryParams.current["end-date"] = dayjs(values.endDate).format(DATE_TIME.YYYY_MM_DD);
			}

			onHandleGetList();
		}, [onHandleGetList]);

	const validationSchema = yup.object({
		endDate: yup.date().test(function (value, { parent }) {
			if (!value) return true;

			const totalDays = getTotalDays(parent.startDate, value);

			return totalDays >= 0 && totalDays <= 90;
		})
	});

	const formik = useFormik({
		initialValues: {
			startDate: "",
			endDate: "",
			status: [STATUS.DRAFT, STATUS.PENDING_ASSIGN, STATUS.PENDING_ASSET, STATUS.OPEN, STATUS.IN_PROGRESS, STATUS.PENDING_ACKNOWLEDGE, STATUS.PENDING_VERIFY, STATUS.VERIFIED, STATUS.COMPLETED, STATUS.CANCELLED, STATUS.OVERDUE, STATUS.PENDING_RESCHEDULE, STATUS.RESCHEDULED]
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const intructionClassName = useMemo(() => classNames({
		"work-inspection-listing-filter__instruction": true,
		"work-inspection-listing-filter__instruction--error": formik.errors.endDate
	}), [formik]);

	//prettier-ignore
	const onHandleEdit = useCallback((tableMeta) => {
		const id = data.content[tableMeta.rowIndex]?.id;

		navigate(pathnames.workInspectionListing.workInspectionListingCreateEdit + id);
	}, [data.content, navigate]);

	const onHandleCreateReport = useCallback(() => {
		navigate(pathnames.workInspectionListing.workInspectionListingCreateEdit + PAGE.CREATE);
	}, [navigate]);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current.status = defaultStatus;
		queryParams.current["start-date"] = "";
		queryParams.current["end-date"] = "";

		onHandleGetList();
	}, [formik, onHandleGetList, defaultStatus]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Draft", name: STATUS.DRAFT },
			{ label: "Pending Assignation", name: STATUS.PENDING_ASSIGN },
			{ label: "Pending Assets", name: STATUS.PENDING_ASSET },
			{ label: "Open", name: STATUS.OPEN },
			{ label: "In Progress", name: STATUS.IN_PROGRESS },
			{ label: "Pending Acknowledge", name: STATUS.PENDING_ACKNOWLEDGE },
			{ label: "Pending Verification", name: STATUS.PENDING_VERIFY },
			{ label: "Verified", name: STATUS.VERIFIED },
			{ label: "Completed", name: STATUS.COMPLETED },
			{ label: "Cancelled", name: STATUS.CANCELLED },
			{ label: "Overdue", name: STATUS.OVERDUE },
			{ label: "Pending Rescheduled", name: STATUS.PENDING_RESCHEDULE },
			{ label: "Rescheduled", name: STATUS.RESCHEDULED }
		];

		return data;
	}, []);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.status ? queryParams.current.status.split(",") : []);
	}, [formik]);

	// prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "referenceNo",
			label: "Work Inspection ID",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "customerDetail.customer.registeredName",
			label: "Registered Name",
			options: {
				sort: true,
				sortThirdClickReset: true,
			},
		},
		{
			name: "noOfAssets",
			label: "No. of Assets",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;

					return data.content[rowIndex].wirAsset.length;
				}			
			},
		},
		{
			name: "scheduledDate",
			label: "Scheduled Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY)
			},
		},
		{
			name: "preparedBy.personalInfo.fullName",
			label: "Prepared By",
			options: {
				sort: false
			},
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => <AppStatus status={value} />
			},
		},
		{
			name: "edit",
			label: "Edit",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={() => onHandleEdit(tableMeta)}>
							<img src={editIcon} alt="edit-icon" />
						</button>
					);
				},
			},
		},
	], [data, onHandleEdit]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		enableNestedDataAccess: ".",
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		onHandleGetList();

		return () => {
			memoCancelRequest(ENDPOINT_PATH.WORK_INSPECTION_REPORT.REPORTS);
		};
	}, [memoCancelRequest, onHandleGetList]);

	return (
		<div className="page-work-inspection-listing">
			<div className="work-inspection-listing">
				<h1 className="work-inspection-listing__title">Work Inspection Listing</h1>

				<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Work Order ID or Customer Name" searchDefaultValue={queryParams.current.keyword} onHandleSearch={onHandleSearch} restricted={restricted} onHandleAdd={onHandleCreateReport} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
					<div className="work-inspection-listing-filter">
						<div className="work-inspection-listing-filter__container">
							<div className="work-inspection-listing-filter__inputs">
								<AppInputDate name="startDate" label="Scheduled Date" placeholder="Start Date" onChange={formik.setFieldValue} value={formik.values.startDate} />

								<AppInputDate name="endDate" placeholder="End Date" onChange={formik.setFieldValue} value={formik.values.endDate} />
							</div>

							<p className={intructionClassName}>Please select a date or a date range of up to 90 days</p>
						</div>

						<div className="work-inspection-listing-filter__container">
							<p className="work-inspection-listing-filter__label">Status</p>

							<div className="work-inspection-listing-filter__checkbox-status">
								{menuFilterStatus.map((o) => {
									const isActiveStatus = formik.values.status.findIndex((i) => i === o.name) > -1;

									return (
										<div className="work-inspection-listing-filter__checkbox" key={o.label}>
											<AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActiveStatus} />
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</AppTableFilterHeader>

				<div className="work-inspection-listing__body">
					<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
				</div>
			</div>
		</div>
	);
};

export default PageWorkInspectionListing;
