import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import PAGE from "constants/page";
import ENDPOINT_PATH from "constants/end-point-path";

import AppButton from "components/app-button";
import AppTable from "components/app-table/app-table";
import AppReportDetailsTeamMemberCreateEditModal from "components/pages/work-inspection-listing/app-report-details-team-member-create-edit-modal";

import addIcon from "assets/images/blue-add-icon.svg";
import deleteIcon from "assets/images/trash-icon.png";

const AppReportDetailsTeamMemberTable = (props) => {
	const { id } = useParams();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const reportDetailsTeamMemberRef = useRef();
	const [data, setData] = useState(convertPaginationTableData());
	const queryParams = useRef({ page: 0 });
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const teamMembersData = useMemo(() => props.teamMembersData, [props.teamMembersData]);
	const isBeforeInProgress = useMemo(() => props.isBeforeInProgress, [props.isBeforeInProgress]);
	const employeeId = useMemo(() => (isCreate ? "employeeDetail.employeeId" : "employeeId"), [isCreate]);
	const onHandleUpdateTeamMembersLocal = useMemo(() => props.onHandleUpdateTeamMembersLocal, [props.onHandleUpdateTeamMembersLocal]);
	const onHandleUpdateTeamMembers = useMemo(() => props.onHandleUpdateTeamMembers, [props.onHandleUpdateTeamMembers]);

	const onHandleAddReportDetailTeamMember = useCallback(() => {
		reportDetailsTeamMemberRef.current.onHandleShow(teamMembersData);
	}, [teamMembersData]);

	const onHandleGetList = useCallback(async () => {
		let response = null;

		const params = sanitizeObject({ ...queryParams.current, size: 10, "work-inspection-id": id });

		try {
			response = await api.get.workInspectionReport.teamMembers(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, [id]);

	// prettier-ignore
	const onHandleRemoveTeamMember = useCallback(async (teamMember) => {
		let nextTeamMembersData = structuredClone(teamMembersData);

		if (isCreate) {
			nextTeamMembersData = nextTeamMembersData.filter((o) => o.id !== teamMember.id);

			onHandleUpdateTeamMembersLocal(nextTeamMembersData);
		}

		if (!isCreate) {
			let response = null;

			try {
				const params = { workInspectionId: id, employeeId: teamMember.employeeId };

				response = await api.post.workInspectionReport.deleteTeamMember(params);
			} catch (error) {
				serveLayoutRequestErrors(error);
			}

			if (response) {
				const obj = convertPaginationTableData(response);

				setData(obj);
			}

			nextTeamMembersData = nextTeamMembersData.filter((o) => o.id !== teamMember.employeeId);

			onHandleUpdateTeamMembersLocal(nextTeamMembersData);

			onHandleGetList();
		}
	}, [id, isCreate, onHandleGetList, onHandleUpdateTeamMembersLocal, teamMembersData]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false
			}
		},
		{
			name: employeeId,
			label: "Employee ID",
			options: {
				sort: false
			}
		},
		{
			name: "personalInfo.fullName",
			label: "Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "remove",
			label: "Remove",
			options: {
				sort: false,
				display: isBeforeInProgress ? "true" : "excluded",
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const obj = data.content[rowIndex];

					return (
						<button type="button" className="table__action" onClick={() => onHandleRemoveTeamMember(obj)}>
							<img src={deleteIcon} alt="delete-icon" />
						</button>
					);
				}
			}
		}
	], [data.content, employeeId, isBeforeInProgress, onHandleRemoveTeamMember]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		enableNestedDataAccess: ".",
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		if (isCreate) {
			const teamMembersDataCopy = structuredClone(teamMembersData);

			setData({ content: teamMembersDataCopy });
		} else {
			onHandleGetList(id);
		}
	}, [id, isCreate, onHandleGetList, teamMembersData]);

	useEffect(() => {
		return () => {
			if (!isCreate) cancelRequest(ENDPOINT_PATH.WORK_INSPECTION_REPORT.TEAM_MEMBERS);
		};
	}, [cancelRequest, isCreate]);

	return (
		<div className="app-report-details-team-member-table">
			<div className="report-details-team-member-table__header">
				<p className="report-details-team-member-table__label report-details-team-member-table__label--table">Team Members</p>

				<AppButton outline type="button" label="Add Team Members" icon={addIcon} onClick={onHandleAddReportDetailTeamMember} />
			</div>

			<div className="report-details-team-member-table">
				<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
			</div>

			<AppReportDetailsTeamMemberCreateEditModal ref={reportDetailsTeamMemberRef} onHandleUpdateTeamMembersLocal={onHandleUpdateTeamMembersLocal} onHandleUpdateTeamMembers={onHandleUpdateTeamMembers} onHandleGetList={onHandleGetList} />
		</div>
	);
};

export default AppReportDetailsTeamMemberTable;
