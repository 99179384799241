import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useSelector } from "react-redux";

import api from "services/api";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ROLES from "constants/roles";
import STATUS from "constants/status";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppInputDate from "components/app-input-date";
import AppInputDragAndDropFiles from "components/app-input-drag-and-drop-files";

import closeIcon from "assets/images/close-icon.png";

const AppPendingClaimModal = (props, ref) => {
	const downloadingFile = useRef(false);
	const [visible, setVisible] = useState(false);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CLAIM_TYPE_CONFIG], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);

	const initialValues = useMemo(() => {
		const values = {
			id: "",
			name: "",
			status: "",
			receiptAmount: "",
			claimType: "",
			submitDate: "",
			description: "",
			receiptDate: "",
			file: []
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues
	});

	//prettier-ignore
	const onHandleShow = useCallback((obj) => {
		formik.setValues({
			id: obj?.id,
			name: obj?.name,
			receiptAmount: obj?.receiptAmount,
			status: obj?.status,
			claimType: obj?.claimType,
			submitDate: dayjs(obj?.date),
			receiptDate: dayjs(obj?.date),
			description: obj?.description,
			file: obj?.docs || []
		});

		setVisible(true);
	}, [formik]);

	const isPending = useMemo(() => formik.values.status === STATUS.PENDING || formik.values.status === STATUS.APPROVED_BY_SBU, [formik]);
	const isApprovedByHr = useMemo(() => formik.values.status === STATUS.APPROVED, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	const onHandleDownloadFile = useCallback(async (fileObject) => {
		if (downloadingFile.current) return;

		downloadingFile.current = true;

		let response = null;

		try {
			const payload = { "doc-id": fileObject.id };

			response = await api.post.humanResource.downloadClaimDoc(payload);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const url = response.fileUrl;
			const a = document.createElement("a");
			document.body.appendChild(a);

			a.href = url;
			a.download = response.fileName;
			a.click();

			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
				downloadingFile.current = false;
			}, 0);
		}
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	const onHandleApprove = useCallback(() => {
		setVisible(false);

		props.onHandleApproveClaim(formik.values.id);
	}, [formik, props]);

	const onHandleReject = useCallback(() => {
		setVisible(false);

		props.onHandleRejectClaim(formik.values.id);
	}, [formik, props]);

	const onHandleSetAsPaid = useCallback(() => {
		setVisible(false);

		props.onHandlePaid(formik.values.id);
	}, [formik, props]);

	const FooterDetailsButton = useCallback((obj) => {
		return (
			<div className="pending-claim-modal__footer-button">
				<div className="pending-claim-modal__reject-button">
					<AppButton outline type="button" disabled={obj.restricted} label="Reject" onClick={obj.onHandleReject} />
				</div>

				<div className="pending-claim-modal__approve-button">
					<AppButton type="button" disabled={obj.restricted} label="Approve" onClick={obj.onHandleApprove} />
				</div>
			</div>
		);
	}, []);

	return (
		<Modal classes={{ root: "app-pending-claim-modal" }} open={visible}>
			<div className="pending-claim-modal">
				<button type="button" className="pending-claim-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="pending-claim-modal__title">Claim Details</h1>

				<div className="pending-claim-modal__container">
					<div className="pending-claim-modal__row">
						<AppInput disabled type="text" name="name" label="Name" value={formik.values.name} error={formik.errors.name} touched={formik.touched.name} onChange={formik.handleChange} />

						<AppInput disabled type="text" name="id" label="Employee ID" value={formik.values.id} error={formik.errors.id} touched={formik.touched.id} onChange={formik.handleChange} />
					</div>

					<div className="pending-claim-modal__row">
						<AppInput disabled type="text" name="status" label="Status" value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />

						<AppInput disabled type="text" name="claimType" label="Claim Type" value={formik.values.claimType} error={formik.errors.claimType} touched={formik.touched.claimType} onChange={formik.handleChange} />
					</div>

					<div className="pending-claim-modal__row">
						<AppInput disabled type="text" name="receiptAmount" label="Amount (MYR)" value={formik.values.receiptAmount} error={formik.errors.receiptAmount} touched={formik.touched.receiptAmount} onChange={formik.handleChange} />

						<AppInputDate disabled type="text" name="receiptDate" label="Receipt Date" value={formik.values.receiptDate} error={formik.errors.receiptDate} touched={formik.touched.receiptDate} onChange={formik.handleChange} />
					</div>

					<div className="pending-claim-modal__row">
						<AppInputDate disabled type="text" name="submitDate" label="Submit Date" value={formik.values.submitDate} error={formik.errors.submitDate} touched={formik.touched.submitDate} onChange={formik.handleChange} />
					</div>

					<AppInput disabled type="textarea" name="description" label="Description" multiline value={formik.values.description} touched={formik.touched.description} error={formik.errors.description} onChange={formik.handleChange} />

					<AppInputDragAndDropFiles disabled name="file" value={formik.values.file} onHandleDownloadFile={onHandleDownloadFile} onChange={formik.setFieldValue} />

					{isApprovedByHr && <AppButton type="button" disabled={restricted} className="pending-claim-modal__paid-button" label="Set as Paid" onClick={onHandleSetAsPaid} />}

					{isPending && <FooterDetailsButton restricted={restricted} onHandleReject={onHandleReject} onHandleApprove={onHandleApprove} />}
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppPendingClaimModal));

AppPendingClaimModal.propTypes = {
	onHandlePaid: PropTypes.func,
	onHandleRejectClaim: PropTypes.func,
	onHandleApproveClaim: PropTypes.func
};
