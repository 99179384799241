import { forwardRef, memo, useImperativeHandle, useMemo } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import getLevelsListing from "services/get-levels-listing";
import getWeekdaysListing from "services/get-week-days-listing";
import getPositionsListing from "services/get-positions-listing";
import getDepartmentsListing from "services/get-department-listing";
import getClaimPackageListing from "services/get-claim-package-listing";
import getLeavePackageListing from "services/get-leave-package-listing";
import getMalaysiaBankListing from "services/get-malaysia-bank-listing";
import getEmploymentStatusListing from "services/get-employment-status-listing";
import getEmployeeMaintenanceListing from "services/get-employee-maintenance-listing";

import PAGE from "constants/page";
import REGEX from "constants/regex";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppInputDate from "components/app-input-date";
import AppInputTime from "components/app-input-time";
import AppSelectInput from "components/app-select-input";

const AppEmployeeInfo = (props, ref) => {
	const { id } = useParams();
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const navigate = useNavigate();
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.EMPLOYEES], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);

	const initialValues = useMemo(() => {
		let data = {
			epfNo: "",
			remark: "",
			socsoNo: "",
			bankName: "",
			swiftCode: "",
			workEmail: "",
			employeeId: "",
			jobLevelId: "",
			positionId: "",
			bankAccount: "",
			dateJoined: "",
			endWorkHour: "",
			endWorkWeek: "",
			incomeTaxNo: "",
			departmentId: "",
			startWorkHour: "",
			startWorkWeek: "",
			employmentStatus: "",
			probationEndDate: "",
			lastEmploymentDate: "",
			leaveBenefitPackageId: "",
			claimBenefitPackageId: "",
			supervisorId: "",
			reportingManagerId: ""
		};

		if (props.employee) {
			data = props.employee;
		}

		return data;
	}, [props.employee]);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: initialValues,
		validationSchema: yup.object({
			bankName: yup.string().required(ERRORS.REQUIRED),
			positionId: yup.string().required(ERRORS.REQUIRED),
			jobLevelId: yup.string().required(ERRORS.REQUIRED),
			endWorkWeek: yup.string().required(ERRORS.REQUIRED),
			bankAccount: yup.string().required(ERRORS.REQUIRED),
			departmentId: yup.string().required(ERRORS.REQUIRED),
			leaveBenefitPackageId: yup.string().required(ERRORS.REQUIRED),
			claimBenefitPackageId: yup.string().required(ERRORS.REQUIRED),
			startWorkWeek: yup.string().required(ERRORS.REQUIRED),
			employmentStatus: yup.string().required(ERRORS.REQUIRED),
			dateJoined: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
			endWorkHour: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
			startWorkHour: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
			probationEndDate: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
			workEmail: yup.string().matches(REGEX.EMAIL, ERRORS.REQUIRED).required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			props.onSubmit(values);
		}
	});

	const onHandleCancel = () => {
		navigate(pathnames.operations.userAccess);
	};

	useImperativeHandle(ref, () => ({
		onHandleSubmit: formik.handleSubmit
	}));

	return (
		<div className="app-employee-info">
			<div className="employee-info">
				<form className="employee-info__form" onSubmit={formik.handleSubmit}>
					<div className="employee-info__container">
						<div className="employee-info__label">General</div>

						<div className="employee-info__row">
							{!isCreate && /* prettier-ignore */ <AppInput disabled name="employeeId" type="text" required label="Employee ID" placeholder="Employee ID" touched={formik.touched.employeeId} error={formik.errors.employeeId} value={formik.values.employeeId} onChange={formik.handleChange} />}

							{/* prettier-ignore */}
							<AppSelectInput required searchable={false} disabled={restricted} name="employmentStatus" label="Employment Status" placeholder="Select..." loadOptions={getEmploymentStatusListing} value={formik.values.employmentStatus} error={formik.errors.employmentStatus} touched={formik.touched.employmentStatus} onChange={formik.handleChange} />
						</div>

						<div className="employee-info__row">
							{/* prettier-ignore */}
							<AppSelectInput searchable={false} disabled={restricted} name="reportingManagerId" label="Reporting Manager" placeholder="Select..." loadOptions={getEmployeeMaintenanceListing} value={formik.values.reportingManagerId} error={formik.errors.reportingManagerId} touched={formik.touched.reportingManagerId} onChange={formik.handleChange} />

							{/* prettier-ignore */}
							<AppSelectInput searchable={false} disabled={restricted} name="supervisorId" label="Supervisor" placeholder="Select..." loadOptions={getEmployeeMaintenanceListing} value={formik.values.supervisorId} error={formik.errors.supervisorId} touched={formik.touched.supervisorId} onChange={formik.handleChange} />
						</div>

						<div className="employee-info__row">
							{/* prettier-ignore */}
							<AppSelectInput required pagination searchable={false} disabled={restricted} name="positionId" label="Position" placeholder="Select..." loadOptions={getPositionsListing} value={formik.values.positionId} error={formik.errors.positionId} touched={formik.touched.positionId} onChange={formik.handleChange} />

							{/* prettier-ignore */}
							<AppSelectInput required pagination searchable={false} disabled={restricted} name= "departmentId" label="Department" placeholder="Select..." loadOptions={getDepartmentsListing} value={formik.values.departmentId } error={formik.errors.departmentId } touched={formik.touched.departmentId } onChange={formik.handleChange} />
						</div>

						<div className="employee-info__row employee-info__row--divider">
							{/* prettier-ignore */}
							<AppSelectInput required pagination searchable={true} disabled={restricted} name="jobLevelId" label="Job Level" placeholder="Select..." loadOptions={getLevelsListing} value={formik.values.jobLevelId} error={formik.errors.jobLevelId} touched={formik.touched.jobLevelId} onChange={formik.handleChange} />

							{/* prettier-ignore */}
							<AppInput required type="text" disabled={restricted} name="workEmail" label="Work Email" placeholder="Enter Work Email" value={formik.values.workEmail} error={formik.errors.workEmail} touched={formik.touched.workEmail} onChange={formik.handleChange} />
						</div>

						<div className="employee-info__label">Schedule</div>

						<div className="employee-info__row">
							{/* prettier-ignore */}
							<AppInputTime required disabled={restricted} name="startWorkHour" label="Start of Work Hours" placeholder="HH:MM" value={formik.values.startWorkHour} error={formik.errors.startWorkHour} touched={formik.touched.startWorkHour} onChange={formik.setFieldValue} />

							{/* prettier-ignore */}
							<AppInputTime required disabled={restricted} name="endWorkHour" label="End of Work Hours" placeholder="HH:MM" value={formik.values.endWorkHour} error={formik.errors.endWorkHour} touched={formik.touched.endWorkHour} onChange={formik.setFieldValue} />
						</div>

						<div className="employee-info__row">
							{/* prettier-ignore */}
							<AppSelectInput required searchable={false} disabled={restricted} name="startWorkWeek" label="Start of Work Week" placeholder="Select..." loadOptions={getWeekdaysListing} value={formik.values.startWorkWeek} error={formik.errors.startWorkWeek} touched={formik.touched.startWorkWeek} onChange={formik.handleChange} />

							{/* prettier-ignore */}
							<AppSelectInput required searchable={false} disabled={restricted} name="endWorkWeek" label="End of Work Week" placeholder="Select..." loadOptions={getWeekdaysListing} value={formik.values.endWorkWeek} error={formik.errors.endWorkWeek} touched={formik.touched.endWorkWeek} onChange={formik.handleChange} />
						</div>
					</div>

					<div className="employee-info__container">
						<div className="employee-info__label">Remuneration Package</div>

						<div className="employee-info__row">
							{/* prettier-ignore */}
							<AppSelectInput required pagination searchable={false} disabled={restricted} name="leaveBenefitPackageId" label="Leave Package" placeholder="Select..." loadOptions={getLeavePackageListing} value={formik.values.leaveBenefitPackageId} error={formik.errors.leaveBenefitPackageId} touched={formik.touched.leaveBenefitPackageId} onChange={formik.handleChange} />

							{/* prettier-ignore */}
							<AppInputDate required disabled={restricted} name="dateJoined" label="Join Date"  placeholder="DD/MM/YYYY" value={formik.values.dateJoined} error={formik.errors.dateJoined} touched={formik.touched.dateJoined} onChange={formik.setFieldValue} />
						</div>

						<div className="employee-info__row">
							<AppSelectInput required pagination searchable={false} disabled={restricted} name="claimBenefitPackageId" label="Claim Package" placeholder="Select..." loadOptions={getClaimPackageListing} value={formik.values.claimBenefitPackageId} error={formik.errors.claimBenefitPackageId} touched={formik.touched.claimBenefitPackageId} onChange={formik.handleChange} />

							<AppInputDate required disabled={restricted} name="probationEndDate" label="Probation End Date" placeholder="DD/MM/YYYY" value={formik.values.probationEndDate} error={formik.errors.probationEndDate} touched={formik.touched.probationEndDate} onChange={formik.setFieldValue} />
						</div>

						<div className="employee-info__row">
							<div className="employee-info__spacer" />

							{/* prettier-ignore */}
							<AppInputDate disabled={restricted} name="lastEmploymentDate" label="Last Employment Date"  placeholder="DD/MM/YYYY" value={formik.values.lastEmploymentDate} error={formik.errors.lastEmploymentDate} touched={formik.touched.lastEmploymentDate} onChange={formik.setFieldValue} />
						</div>
					</div>

					<div className="employee-info__container">
						<div className="employee-info__label">Bank Info</div>

						<div className="employee-info__row">
							{/* prettier-ignore */}
							<AppSelectInput required searchable={false} disabled={restricted} name="bankName" label="Bank" placeholder="Select..." loadOptions={getMalaysiaBankListing} value={formik.values.bankName} error={formik.errors.bankName} touched={formik.touched.bankName} onChange={formik.handleChange} />

							{/* prettier-ignore */}
							<AppInput required disabled={restricted} name="bankAccount" type="number" label="Bank Account No." placeholder="Bank Account No." touched={formik.touched.bankAccount} error={formik.errors.bankAccount} value={formik.values.bankAccount} onChange={formik.handleChange} />
						</div>

						<div className="employee-info__row">
							{/* prettier-ignore */}
							<AppInput disabled={restricted} name="swiftCode" type="text" label="Swift Code" placeholder="Swift Code" touched={formik.touched.swiftCode} error={formik.errors.swiftCode} value={formik.values.swiftCode} onChange={formik.handleChange} />
						</div>
					</div>

					<div className="employee-info__container">
						<div className="employee-info__label">Contributions</div>

						<div className="employee-info__row">
							{/* prettier-ignore */}
							<AppInput disabled={restricted} name="epfNo" type="number" label="EPF Number" placeholder="EPF Number" touched={formik.touched.epfNo} error={formik.errors.epfNo} value={formik.values.epfNo} onChange={formik.handleChange} />

							{/* prettier-ignore */}
							<AppInput disabled={restricted} name="incomeTaxNo" type="text" label="Income Tax Number" placeholder="Income Tax Number" touched={formik.touched.incomeTaxNo} error={formik.errors.incomeTaxNo} value={formik.values.incomeTaxNo} onChange={formik.handleChange} />
						</div>

						<div className="employee-info__row">
							{/* prettier-ignore */}
							<AppInput disabled={restricted} name="socsoNo" type="number" label="SOCSO Number" placeholder="SOCSO Number" touched={formik.touched.socsoNo} error={formik.errors.socsoNo} value={formik.values.socsoNo} onChange={formik.handleChange} />
						</div>
					</div>

					<div className="employee-info__container">
						<div className="employee-info__label">Remarks</div>

						<div className="employee-info__row">
							{/* prettier-ignore */}
							<AppInput disabled={restricted} required multiline length={500} type="textarea" name="remark" placeholder="Type Note here" value={formik.values.remark} error={formik.errors.remark} touched={formik.touched.remark} onChange={formik.handleChange} />
						</div>
					</div>

					<div className="employee-info__button-container">
						<AppButton outline type="button" onClick={onHandleCancel} label="Cancel" />

						<AppButton type="submit" label="Next" />
					</div>
				</form>
			</div>
		</div>
	);
};

export default memo(forwardRef(AppEmployeeInfo));

AppEmployeeInfo.propTypes = {
	onSubmit: PropTypes.func,
	employee: PropTypes.object
};
