import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import classNames from "common/class-names";
import combineDateTime from "common/combine-date-time";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ERRORS from "constants/errors";
import WORK_ORDER_TYPE from "constants/work-order-type";

import AppButton from "components/app-button";
import AppInputDate from "components/app-input-date";
import AppInputTime from "components/app-input-time";
import AppTable from "components/app-table/app-table";
import AppSelectInput from "components/app-select-input";

const workOrderTypeOptions = [
	{ label: "Preventive", value: WORK_ORDER_TYPE.PREVENTIVE },
	{ label: "Corrective", value: WORK_ORDER_TYPE.CORRECTIVE },
	{ label: "Breakdown", value: WORK_ORDER_TYPE.BREAKDOWN },
	{ label: "Project", value: WORK_ORDER_TYPE.PROJECT }
];

export const AppConvertWorkOrderModal = (props, ref) => {
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);
	const [isFirstStep, setIsFirstStep] = useState(true);
	const [data, setData] = useState([]);

	const modalClassName = useMemo(() => {
		return classNames({
			"convert-work-order-modal": true,
			"convert-work-order-modal--second-step": !isFirstStep
		});
	}, [isFirstStep]);

	const buttonContainerClassName = useMemo(() => {
		return classNames({
			"convert-work-order-modal__button-container": true,
			"convert-work-order-modal__button-container--second-step": !isFirstStep
		});
	}, [isFirstStep]);

	const initialValues = useMemo(() => {
		const values = {
			workInspectionId: "",
			scheduledDate: "",
			scheduledTime: "",
			targetCompletionDate: "",
			targetCompletionTime: "",
			wirAsset: []
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			scheduledDate: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
			scheduledTime: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
			targetCompletionDate: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
			targetCompletionTime: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
			wirAsset: yup.array().of(
				yup.object().shape({
					workInspectionAssetId: yup.string().required(ERRORS.REQUIRED),
					orderType: yup.string().required(ERRORS.REQUIRED)
				})
			)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		const payload = {
			workInspectionId: id,
			scheduleDate: combineDateTime(values.scheduledDate, values.scheduledTime),
			targetCompletionDate: combineDateTime(values.targetCompletionDate, values.targetCompletionTime),
			wirAsset: values.wirAsset
		};

		try {
			response = await api.post.workInspectionReport.autoConvert(payload);
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Work Orders Report were generated successfully!" }));

			setVisible(false);

			navigate(pathnames.workInspectionListing.workInspectionListingCreateEdit + id + +"?tab=WORK_ORDERS");
		}
	}, [dispatch, formik, id, navigate]);

	//prettier-ignore
	const onHandleShow = useCallback((values) => {
		const processedWirAssets = values.map((o) => ({
			workInspectionAssetId: o.id,
			orderType: ""
		}));

		setData(values);

		formik.setFieldValue("wirAsset", processedWirAssets);

		setVisible(true);
	}, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	const onHandleNextStep = useCallback(async () => {
		const fieldErrors = await formik.setFieldTouched("wirAsset");

		if (fieldErrors.wirAsset) return;

		setIsFirstStep(false);
	}, [formik]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false
			}
		},
		{
			name: "assetName",
			label: "Asset Name",
			options: {
				sort: false
			}
		},
		{
			name: "customerAssetName",
			label: "Customer Asset Name",
			options: {
				sort: false
			}
		},
		{
			name: "assetType",
			label: "Asset Type",
			options: {
				sort: false
			}
		},
		{
			name: "workspace",
			label: "Work Space",
			options: {
				sort: false
			}
		},
		{
			name: "workOrderType",
			label: "Work Order Type",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;

					return <AppSelectInput name={`wirAsset[${rowIndex}].orderType`} label="" placeholder="Select..." options={workOrderTypeOptions} value={formik.values.wirAsset?.[rowIndex]?.orderType} error={formik.errors.wirAsset?.[rowIndex]?.orderType} touched={formik.touched.wirAsset} onChange={formik.handleChange} />;
				}
			}
		}
	], [formik.values.wirAsset, formik.errors.wirAsset, formik.touched.wirAsset, formik.handleChange]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.length,
		enableNestedDataAccess: "."
	}), [data]);

	const ChooseWorkOrder = useCallback((obj) => {
		return (
			<div className="convert-work-order-modal__container">
				<p className="convert-work-order-modal__description">
					<span>Step 1</span> - Choose the Work Order Type for each of the Assets.
				</p>

				<AppTable data={obj.data} columns={obj.tableColumns} />

				<div className={obj.buttonContainerClassName}>
					<AppButton outline type="button" label="Cancel" onClick={obj.onHandleDismiss} />

					<AppButton type="button" label="Next" onClick={obj.onHandleNextStep} />
				</div>
			</div>
		);
	}, []);

	const Schedule = useCallback((obj) => {
		return (
			<div className="convert-work-order-modal__container">
				<p className="convert-work-order-modal__description">
					<span>Step 2</span> - Choose the schedule date and time for the work orders.
				</p>

				<AppInputDate required name="scheduledDate" label="Scheduled Date" placeholder="DD/MM/YYYY" value={obj.formik.values.scheduledDate} error={obj.formik.errors.scheduledDate} touched={obj.formik.touched.scheduledDate} onChange={obj.formik.setFieldValue} />

				<AppInputTime required name="scheduledTime" label="Scheduled Time" placeholder="HH:MM" value={obj.formik.values.scheduledTime} error={obj.formik.errors.scheduledTime} touched={obj.formik.touched.scheduledTime} onChange={obj.formik.setFieldValue} />

				<AppInputDate required name="targetCompletionDate" label="Target Completion Date" placeholder="DD/MM/YYYY" value={obj.formik.values.targetCompletionDate} error={obj.formik.errors.targetCompletionDate} touched={obj.formik.touched.targetCompletionDate} onChange={obj.formik.setFieldValue} />

				<AppInputTime required name="targetCompletionTime" label="Target Completion Time" placeholder="HH:MM" value={obj.formik.values.targetCompletionTime} error={obj.formik.errors.targetCompletionTime} touched={obj.formik.touched.targetCompletionTime} onChange={obj.formik.setFieldValue} />

				<div className={obj.buttonContainerClassName}>
					<AppButton outline type="button" label="Back" onClick={() => setIsFirstStep(true)} />

					<AppButton type="submit" label="Confirm" />
				</div>
			</div>
		);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-convert-work-order-modal" }} open={visible}>
			<div className={modalClassName}>
				<h1 className="convert-work-order-modal__title">Convert To Work Order</h1>
				<form className="convert-work-order-modal__form" onSubmit={formik.handleSubmit}>
					{isFirstStep && <ChooseWorkOrder buttonContainerClassName={buttonContainerClassName} data={data} tableColumns={tableColumns} tableOptions={tableOptions} onHandleNextStep={onHandleNextStep} onHandleDismiss={onHandleDismiss} />}

					{!isFirstStep && <Schedule buttonContainerClassName={buttonContainerClassName} formik={formik} />}
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppConvertWorkOrderModal));

AppConvertWorkOrderModal.propTypes = {
	ref: PropTypes.object
};
