import React, { useCallback, useMemo, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";

import api from "services/api";

import useBeforeUnload from "hooks/use-before-unload";

import serveRequestErrors from "common/serve-request-errors";

import PAGE from "constants/page";
import SIGNATURE_TYPE from "constants/signature-type";

import AppTabs from "components/app-tabs";
import AppButton from "components/app-button";
import AppWorkOrderWorkDetails from "components/pages/work-order/app-work-order-work-details";
import AppWorkCompletionReportDetails from "components/pages/work-completion-report/app-work-completion-report-details";
import AppWorkCompletionReportCancelConfirmationModal from "components/pages/work-completion-report/app-work-completion-report-cancel-confirmation-modal";

import exportIcon from "assets/images/export-icon.png";
import chevronIcon from "assets/images/chevron-right-light-blue-icon.svg";
import workDetailsIcon from "assets/images/pages/work-completion-report/work-details-icon.svg";
import reportDetailsIcon from "assets/images/pages/work-completion-report/report-details-icon.svg";
import workDetailsActiveIcon from "assets/images/pages/work-completion-report/work-details-active-icon.svg";
import reportDetailsActiveIcon from "assets/images/pages/work-completion-report/report-details-active-icon.svg";

const PageWorkCompletionReportCreateEdit = () => {
	useBeforeUnload();
	const { id } = useParams();
	const [wcrInfo, setWcrInfo] = useState({});
	const [menuAnchor, setMenuAnchor] = useState(null);
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const title = useMemo(() => (isCreate ? "Create Work Completion Report" : "Edit Work Completion Report"), [isCreate]);
	const tabRef = useRef();
	const cancelConfirmationModal = useRef();

	const onHandleSetWcrInfo = useCallback((data) => {
		setWcrInfo(data);
	}, []);

	const onToggleFilterMenu = useCallback((event) => {
		setMenuAnchor(event.currentTarget);
	}, []);

	const onHandleCloseMenu = useCallback(() => {
		setMenuAnchor(null);
	}, []);

	const onHandleSetCancelled = () => {
		setMenuAnchor(false);

		wcrInfo.signatureType = SIGNATURE_TYPE.CANCELLED_BY;

		cancelConfirmationModal.current.onHandleShow(wcrInfo);
	};

	// TODO: When send for signature feature is ready
	const onHandleSendForSignature = () => {
		setMenuAnchor(false);
	};

	const onHandleExportPDF = useCallback(async () => {
		let response = null;
		let fileName = "";

		try {
			const payload = { "work-completion-id": id };

			const transformResponse = (data, headers) => {
				fileName = headers?.["content-disposition"]?.split("inline; filename=")?.[1]?.split(";")?.[0];

				if (fileName) return data;

				try {
					const jsonResponse = JSON.parse(new TextDecoder().decode(data));

					if (jsonResponse) return jsonResponse;
				} catch (error) {
					return data;
				}
			};

			response = await api.post.workCompletionReport.generateWorkCompletionReportPdf(payload, { transformResponse });
		} catch (error) {
			serveRequestErrors(error);
		}

		if (response) {
			const a = document.createElement("a");
			document.body.appendChild(a);
			const url = window.URL.createObjectURL(new Blob([response]), { type: "application/octet-stream" });
			a.href = url;
			a.download = fileName;
			a.click();

			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
			}, 1000);
		}
	}, [id]);

	//prettier-ignore
	const HeaderLinkButton = useCallback((obj) => {
		if (!obj.isCreate)
			return (
				<div className="work-completion-report-create-edit__header-button">
					<AppButton className="work-completion-report-create-edit__action-button" type="button" label="Actions" icon={chevronIcon}  onClick={obj.onToggleFilterMenu} />

					<AppButton type="button" label="Export as PDF" icon={exportIcon} onClick={obj.onHandleExportPDF} />
				</div>
			);
	}, []);

	//prettier-ignore
	const tabs = useMemo(() => {
		const data = [
			{ label: "Report Details", icon: reportDetailsIcon, activeIcon: reportDetailsActiveIcon, name: "REPORT_DETAILS", component: <AppWorkCompletionReportDetails onHandleSetWcrInfo={onHandleSetWcrInfo} />, accessible: true },
			{ label: "Work Details", icon: workDetailsIcon, activeIcon: workDetailsActiveIcon, name: "WORK_DETAILS", component: <AppWorkOrderWorkDetails />, accessible: true },
		];

		return data;
	}, [onHandleSetWcrInfo]);

	return (
		<div className="page-work-completion-report-create-edit">
			<div className="work-completion-report-create-edit">
				<div className="work-completion-report-create-edit__header">
					<h1 className="work-completion-report-create-edit__title">{title}</h1>

					<HeaderLinkButton isCreate={isCreate} onToggleFilterMenu={onToggleFilterMenu} onHandleExportPDF={onHandleExportPDF} />
				</div>

				<div className="work-completion-report-create-edit__body">
					{!isCreate && (
						<p className="work-completion-report-create-edit__last-update">
							<span>Last Updated By</span> {wcrInfo.lastModifiedByName}, {wcrInfo.lastModifiedByDate}
						</p>
					)}

					<AppTabs ref={tabRef} tabs={tabs} />
				</div>
			</div>

			<Menu classes={{ root: "work-completion-report-create-edit-menu" }} anchorEl={menuAnchor} open={!!menuAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem onClick={onHandleSetCancelled}>Set As Cancelled</MenuItem>

				<MenuItem onClick={onHandleSendForSignature}>Send for Signature</MenuItem>
			</Menu>

			<AppWorkCompletionReportCancelConfirmationModal ref={cancelConfirmationModal} />
		</div>
	);
};

export default PageWorkCompletionReportCreateEdit;
