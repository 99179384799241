import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import dayjs from "dayjs";
import { useFormik } from "formik";
import { Modal } from "@mui/material";

import api from "services/api";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import STATUS from "constants/status";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppInputDate from "components/app-input-date";

import closeIcon from "assets/images/close-icon.png";
import chevronIcon from "assets/images/chevron-right-icon.png";

const AppPendingCorrectiveAttendanceViewEmployeeModal = (props, ref) => {
	const [visible, setVisible] = useState(false);
	const onHandleGetList = useMemo(() => props.onHandleGetList, [props.onHandleGetList]);

	const formik = useFormik({
		initialValues: {
			name: "",
			id: "",
			status: "",
			date: "",
			submitDate: "",
			previousClockIn: "",
			correctedClockIn: "",
			previousClockOut: "",
			correctedClockOut: "",
			remarks: ""
		},
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const onHandleShow = useCallback((obj) => {
		formik.setValues({
			name: obj?.name || "",
			id: obj?.id || "",
			status: obj?.status || STATUS.APPROVED,
			reviewedBy: obj?.reviewedBy || "",
			date: dayjs(obj?.date) || "",
			submitDate: dayjs(obj?.date) || "",
			previousClockIn: obj?.previousClockIn || "",
			correctedClockIn: obj?.correctedClockIn || "",
			previousClockOut: obj?.previousClockOut || "",
			correctedClockOut: obj?.correctedClockOut || "",
			remarks: obj?.description || "N/A",
			rejectReason: obj?.description || "N/A"
		});

		setVisible(true);
	}, [formik]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (status) => {
		let response = null;

		const values = structuredClone(formik.values);

		const payload = [
			{
				id: values.id,
				status: status,
				remark: values.remarks
			}
		];

		try {
			await api.post.humanResource.updateCorrectiveAttendanceStatus(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			onHandleGetList();

			setVisible(false);
		}
	}, [formik.values, onHandleGetList]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-pending-corrective-attendance-view-employee-modal" }} open={visible}>
			<div className="pending-corrective-attendance-view-employee-modal">
				<button type="button" className="pending-corrective-attendance-view-employee-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="pending-corrective-attendance-view-employee-modal__title">Corrective Attendance Details</h1>

				<form className="pending-corrective-attendance-view-employee-modal__form" onSubmit={formik.handleSubmit}>
					<div className="pending-corrective-attendance-view-employee-modal__row">
						<AppInput disabled type="text" name="name" label="Name" value={formik.values.name} error={formik.errors.name} touched={formik.touched.name} onChange={formik.handleChange} />

						<AppInput disabled type="text" name="id" label="Employee ID" value={formik.values.id} error={formik.errors.id} touched={formik.touched.id} onChange={formik.handleChange} />
					</div>

					<div className="pending-corrective-attendance-view-employee-modal__row">
						<AppInput disabled type="text" name="status" label="Status" value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />
					</div>

					<div className="pending-corrective-attendance-view-employee-modal__row">
						<AppInputDate disabled type="text" name="date" label="Date" value={formik.values.date} error={formik.errors.date} touched={formik.touched.date} onChange={formik.handleChange} />

						<AppInputDate disabled type="text" name="submitDate" label="Submit Date" value={formik.values.submitDate} error={formik.errors.submitDate} touched={formik.touched.submitDate} onChange={formik.handleChange} />
					</div>

					<div className="pending-corrective-attendance-view-employee-modal__row">
						<AppInput disabled type="text" name="previousClockIn" label="Previous Clock In" value={formik.values.previousClockIn} error={formik.errors.previousClockIn} touched={formik.touched.previousClockIn} onChange={formik.handleChange} />

						<AppIcon className="pending-corrective-attendance-view-employee-modal__chevron" src={chevronIcon} />

						<AppInput disabled type="text" name="correctedClockIn" label="Corrected Clock In" value={formik.values.correctedClockIn} error={formik.errors.correctedClockIn} touched={formik.touched.correctedClockIn} onChange={formik.handleChange} />
					</div>

					<div className="pending-corrective-attendance-view-employee-modal__row">
						<AppInput disabled type="text" name="previousClockOut" label="Previous Clock Out" value={formik.values.previousClockOut} error={formik.errors.previousClockOut} touched={formik.touched.previousClockOut} onChange={formik.handleChange} />

						<AppIcon className="pending-corrective-attendance-view-employee-modal__chevron" src={chevronIcon} />

						<AppInput disabled type="text" name="correctedClockOut" label="Corrected Clock Out" value={formik.values.correctedClockOut} error={formik.errors.correctedClockOut} touched={formik.touched.correctedClockOut} onChange={formik.handleChange} />
					</div>

					<AppInput disabled type="textarea" name="remarks" label="Remarks" multiline maxLength={255} value={formik.values.remarks} touched={formik.touched.remarks} error={formik.errors.remarks} onChange={formik.handleChange} />

					<div className="pending-corrective-attendance-view-employee-modal__button-container">
						<div className="pending-corrective-attendance-view-employee-modal__reject-button">
							<AppButton outline type="button" label="Reject" onClick={() => onHandleSubmit(STATUS.REJECTED)} />
						</div>

						<div className="pending-corrective-attendance-view-employee-modal__approve-button">
							<AppButton type="button" label="Approve" onClick={() => onHandleSubmit(STATUS.APPROVED)} />
						</div>
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppPendingCorrectiveAttendanceViewEmployeeModal));
