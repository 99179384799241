import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";
import getStateListing from "services/get-state-listing";
import getCitiesListing from "services/get-region-listing";
import getPostcodeListing from "services/get-postcode-listing";
import getSalesOrderListing from "services/get-sales-order-listing";
import getEmployeeMaintenanceListing from "services/get-employee-maintenance-listing";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import STATUS from "constants/status";
import ENDPOINT_PATH from "constants/end-point-path";
import SIGNATURE_TYPE from "constants/signature-type";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppInputDate from "components/app-input-date";
import AppInputTime from "components/app-input-time";
import AppMobileInput from "components/app-mobile-input";
import AppSelectInput from "components/app-select-input";
import AppInputDragAndDrop from "components/app-input-drag-and-drop";
import AppAssetConfirmationModal from "components/pages/work-completion-report/app-asset-confirmation-modal";

const AppWorkCompletionReportDetails = (props) => {
	const navigate = useNavigate();
	const { id } = useParams();
	const dispatch = useDispatch();
	const profile = useSelector((state) => state.profile);
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	// TODO: Change when WO Role is available
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_MAINTENANCE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const submitLabel = useMemo(() => (isCreate ? "Next" : "Update"), [isCreate]);
	const [signatureType, setSignatureType] = useState("");
	const confirmationRef = useRef();

	const initialValues = useMemo(() => {
		const values = {
			id: "",
			reportId: "",
			status: "",
			workOrderId: "",
			salesOrderId: "",
			workInspectionReportId: "",
			registeredName: "",
			customerPic: "",
			contractId: "",
			siteId: "",
			assetId: "",
			customerAssetName: "",
			prefixNo: "",
			mobileNo: "",
			email: "",
			addressLine1: "",
			addressLine2: "",
			city: "",
			state: "",
			postcode: "",
			reportTitle: "",
			scopeOfWork: "",
			completionDate: "",
			completionTime: "",
			preparedBy: { employeeId: "", name: "", signature: "", signatureFile: null },
			completedBy: { employeeId: "", name: "", signature: "", signatureFile: null },
			acknowledgedBy: { employeeId: "", name: "", signature: "", signatureFile: null }
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			workOrderId: yup.string().required(ERRORS.REQUIRED),
			salesOrderId: yup.string().required(ERRORS.REQUIRED),
			reportTitle: yup.string().required(ERRORS.REQUIRED),
			scopeOfWork: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	//prettier-ignore
	const onHandleGetDetails = useCallback(async (workOrderReportId) => {
		let response = null;

		try {
			response = await api.get.workCompletionReport.report(workOrderReportId);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			memoSetFormValues({
				id: response.id,
				reportId: response.referenceNo,
				status: response.status,
				workOrderId: response.workOrder?.id,
				salesOrderId: response.saleOrderId,
				workInspectionReportId: response.workInspection?.referenceNo,
				registeredName: response.workOrderCustomerDetail?.customer?.registeredName,
				customerPic: response.workOrderCustomerDetail?.customerPicContact?.picName,
				contractId: response.workOrderCustomerDetail?.customerContract?.referenceNo,
				siteId: response.workOrderCustomerDetail?.customerContractSite?.referenceNo,
				assetId: response.woAsset[0].assetName,
				customerAssetName: response.woAsset[0].customerAssetName,
				prefixNo: response.workOrderCustomerDetail?.customerPicContact?.mobileNoPrefix,
				mobileNo: response.workOrderCustomerDetail?.customerPicContact?.mobileNo,
				email: response.workOrderCustomerDetail?.customerPicContact?.email,
				addressLine1: response.workOrderCustomerDetail?.address_1,
				addressLine2: response.workOrderCustomerDetail?.address_2,
				city: response.workOrderCustomerDetail?.city,
				state: response.workOrderCustomerDetail?.state,
				postcode: response.workOrderCustomerDetail?.postcode,
				reportTitle: response.reportTitle,
				scopeOfWork: response.scopeOfWork,
				completionDate: dayjs(response.completionDate),
				completionTime: dayjs(response.comletionDate),
				preparedBy: { employeeId: response.signature?.preparedBy?.id, name: response.signature?.preparedBy?.personalInfo?.fullName, signature: response.signature?.preparedBySignaturePath },
				verifiedBy: { employeeId: response.signature?.verifiedBy?.id, name: response.signature?.verifiedBy?.personalInfo?.fullName, signature: response.signature?.verifiedBySignaturePath },
				acknowledgedBy: { employeeId: response.signature?.acknowledgedBy?.id, name: response.signature?.acknowledgedBy?.personalInfo?.fullName, signature: response.signature?.acknowledgedBySignaturePath },
			});

			props.onHandleSetWcrInfo({ referenceNo: response.referenceNo, employeeCustomerId: response.customerDetail?.customer.id, status: response.status, siteName: response.customerDetail?.customerContractSite.name, assetId: response.woAsset[0].id, customerContractSiteAssetId: response.woAsset[0].customerContractSiteAssetId, lastModifiedBy: response.lastModifiedBy, lastModifiedDate: response.lastModifiedDate });
		}
	}, [memoSetFormValues, props]);

	//prettier-ignore
	const onHandleSetSignature = useCallback((name, value, signatureType) => {
		setSignatureType(signatureType);

		let image = "";
		let signatureName = `${name.split(".")[0]}.signatureFile`;

		if (value) {
			image = URL.createObjectURL(value);
		}

		formik.setFieldValue(name, image);
		formik.setFieldValue(signatureName, value);
	}, [formik]);

	const onHandleGetRelevantSignature = useCallback(() => {
		let signatureObject = {};

		switch (signatureType) {
			case SIGNATURE_TYPE.PREPARED_BY:
				signatureObject = formik.values.preparedBy;
				break;
			case SIGNATURE_TYPE.VERIFIED_BY:
				signatureObject = formik.values.verifiedBy;
				break;
			case SIGNATURE_TYPE.ACKNOWLEDGED_BY:
				signatureObject = formik.values.acknowledgedBy;
				break;
			case SIGNATURE_TYPE.CANCELLED_BY:
				signatureObject = formik.values.cancelledBy;
				break;
			default:
				break;
		}

		return signatureObject;
	}, [formik.values, signatureType]);

	//prettier-ignore
	const onHandleSignatures = useCallback(async (workCompletionId) => {
		let response = null;

		if (!signatureType) return;

		const signatureObj = onHandleGetRelevantSignature();

		try {
			const formData = new FormData();

			formData.append("file", signatureObj.signatureFile);
			formData.append("wcrId", workCompletionId);
			formData.append("employeeCustomerId", signatureObj.employeeId);
			formData.append("signatureType", signatureType);

			await api.post.workCompletionReport.signatureUpload(formData);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Signature was added successfully!" }));
			}

			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Signature was updated successfully!" }));
			}
		}
	}, [dispatch, isCreate, onHandleGetRelevantSignature, signatureType]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = {
				workOrderId: values.workOrderId,
				saleOrderId: values.salesOrderId,
				reportTitle: values.reportTitle,
				scopeOfWork: values.scopeOfWork
			};

			if (isCreate) {
				response = await api.post.workCompletionReport.createWorkCompletionReport(payload);
			}

			if (!isCreate) {
				payload.id = id;

				response = await api.post.workCompletionReport.updateWorkCompletionReport(payload);
			}
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Work Completion Report was added successfully!" }));
			}

			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Work Completion Report was updated successfully!" }));
			}

			onHandleSignatures(response.id);

			onHandleGetDetails(response.id);
		}
	}, [isCreate, id, formik, onHandleSignatures, onHandleGetDetails, dispatch]);

	const onHandleBack = useCallback(() => {
		navigate(pathnames.workCompletionReport.workCompletionReport);
	}, [navigate]);

	useEffect(() => {
		if (!isCreate) onHandleGetDetails(id);
	}, [id, isCreate, onHandleGetDetails]);

	useEffect(() => {
		return () => {
			if (!isCreate) cancelRequest(ENDPOINT_PATH.WORK_COMPLETION_REPORT.REPORT);
		};
	}, [cancelRequest, isCreate]);

	return (
		<div className="app-work-completion-report-details">
			<div className="work-completion-report-details">
				<form className="work-completion-report-details__form" onSubmit={formik.handleSubmit}>
					<div className="work-completion-report-details__container">
						<p className="work-completion-report-details__label">General Details</p>

						{/* prettier-ignore */}
						<div className="work-completion-report-details__row">
							<AppInput disabled type="text" name="reportId" label="Report ID" placeholder="" value={formik.values.reportId} error={formik.errors.reportId} touched={formik.touched.reportId} onChange={formik.handleChange} />

							<AppSelectInput disabled searchable={false} type="text" name="status" label="Status" placeholder="Select..." options={[{label: "Cancelled", value: STATUS.CANCELLED}]} value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />
						</div>

						<div className="work-completion-report-details__row">
							<AppSelectInput disabled={!isCreate} required searchable={false} type="text" name="workOrderId" label="Work Order ID" placeholder="Select..." options={[]} value={formik.values.workOrderId} error={formik.errors.workOrderId} touched={formik.touched.workOrderId} onChange={formik.handleChange} />

							<AppSelectInput disabled={!isCreate} searchable={false} type="text" name="salesOrderId" label="Sales Order ID" placeholder="Select..." loadOptions={getSalesOrderListing} value={formik.values.salesOrderId} error={formik.errors.salesOrderId} touched={formik.touched.salesOrderId} onChange={formik.handleChange} />
						</div>

						<div className="work-completion-report-details__row work-completion-report-details__row--divider">
							<AppInput disabled type="text" name="workInspectionReportId" label="Work Inspection Report ID" placeholder="" value={formik.values.workInspectionReportId} error={formik.errors.workInspectionReportId} touched={formik.touched.workInspectionReportId} onChange={formik.handleChange} />
						</div>

						<p className="work-completion-report-details__label">Customer Details</p>

						{/* prettier-ignore */}
						<div className="work-completion-report-details__row">
							<AppInput disabled type="text" name="registeredName" label="Registered Name" placeholder="Registered Name" value={formik.values.registeredName} error={formik.errors.registeredName} touched={formik.touched.registeredName} onChange={formik.handleChange} />

							<AppInput disabled type="text" name="customerPic" label="Customer PIC" placeholder="" value={formik.values.customerPic} error={formik.errors.customerPic} touched={formik.touched.customerPic} onChange={formik.handleChange} />
						</div>

						<div className="work-completion-report-details__row">
							<AppInput disabled type="text" name="contractId" label="Contract ID" placeholder="" value={formik.values.contractId} error={formik.errors.contractId} touched={formik.touched.contractId} onChange={formik.handleChange} />

							<AppInput disabled type="text" name="siteId" label="Site Name" placeholder="" value={formik.values.siteId} error={formik.errors.siteId} touched={formik.touched.siteId} onChange={formik.handleChange} />
						</div>

						<div className="work-completion-report-details__row">
							<AppInput disabled type="text" name="assetId" label="Asset Name" placeholder="" value={formik.values.assetId} error={formik.errors.assetId} touched={formik.touched.assetId} onChange={formik.handleChange} />

							<AppInput disabled type="text" name="customerAssetName" label="Customer Asset Name" placeholder="Customer Asset Name" value={formik.values.customerAssetName} error={formik.errors.customerAssetName} touched={formik.touched.customerAssetName} onChange={formik.handleChange} />
						</div>

						{/* prettier-ignore */}
						<div className="work-completion-report-details__row">
							<AppMobileInput disabled type="number" name="mobileNo" label="Mobile No." value={formik.values.mobileNo} prefixNo={formik.values.prefixNo} error={formik.errors.mobileNo} touched={formik.touched.mobileNo} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} />

							<AppInput disabled type="text" name="email" label="Email" placeholder="" value={formik.values.email} error={formik.errors.email} touched={formik.touched.email} onChange={formik.handleChange} />
						</div>

						<p className="work-completion-report-details__label">Site Location</p>

						{/* prettier-ignore */}
						<div className="work-completion-report-details__row">
							<AppInput disabled type="text" name="addressLine1" label="Address Line 1" placeholder="Address Line 1" value={formik.values.addressLine1} error={formik.errors.addressLine1} touched={formik.touched.addressLine1} onChange={formik.handleChange} />

							<AppInput disabled type="text" name="addressLine2" label="Address Line 2" placeholder="Address Line 2" value={formik.values.addressLine2} error={formik.errors.addressLine2} touched={formik.touched.addressLine2} onChange={formik.handleChange} />
						</div>

						<div className="work-completion-report-details__row">
							<AppSelectInput disabled name="state" label="State" placeholder="State" loadOptions={getStateListing} value={formik.values.state} error={formik.errors.state} touched={formik.touched.state} onChange={formik.handleChange} />

							<AppSelectInput disabled name="city" label="City" placeholder="City" loadOptions={getCitiesListing} value={formik.values.city} error={formik.errors.city} touched={formik.touched.city} onChange={formik.handleChange} />
						</div>

						<div className="work-completion-report-details__row">
							<AppSelectInput disabled name="postcode" label="Postcode" placeholder="Postcode" loadOptions={getPostcodeListing} value={formik.values.postcode} error={formik.errors.postcode} touched={formik.touched.postcode} onChange={formik.handleChange} />
						</div>

						<p className="work-completion-report-details__label">Content</p>

						<AppInput disabled={restricted} type="text" name="reportTitle" label="Report Title" placeholder="Enter Report Title" value={formik.values.reportTitle} error={formik.errors.reportTitle} touched={formik.touched.reportTitle} onChange={formik.handleChange} />

						<AppInput disabled={restricted} multiline type="textarea" name="scopeOfWork" label="Scope of Work" placeholder="Enter Scope of Work" value={formik.values.scopeOfWork} error={formik.errors.scopeOfWork} touched={formik.touched.scopeOfWork} onChange={formik.handleChange} />

						<div className="work-completion-report-details__row">
							<AppInputDate disabled name="completionDate" label="Completion Date" placeholder="DD/MM/YYYY" value={formik.values.completionDate} error={formik.errors.completionDate} touched={formik.touched.completionDate} onChange={formik.setFieldValue} />

							<AppInputTime disabled name="completionTime" label="Completion Time" placeholder="HH:MM" value={formik.values.completionTime} error={formik.errors.completionTime} touched={formik.touched.completionTime} onChange={formik.setFieldValue} />
						</div>
					</div>

					<div className="work-completion-report-details__container">
						<div className="work-completion-report-details__row work-completion-report-details__row--signature">
							<div className="work-completion-report-details__column">
								<Fragment>
									<AppSelectInput required name="preparedBy.employeeId" label="Prepared By" placeholder="Search by User ID or Name" loadOptions={getEmployeeMaintenanceListing} value={formik.values.preparedBy?.employeeId} error={formik.errors.preparedBy?.employeeId} touched={formik.touched.preparedBy?.employeeId} onChange={formik.handleChange} />

									<AppInputDragAndDrop name="preparedBy.signature" accept="image/png, image/jpeg, image/jpg" value={formik.values.preparedBy?.signature} error={formik.errors.preparedBy?.signature} touched={formik.touched.preparedBy?.signature} onChange={(name, file) => onHandleSetSignature(name, file, SIGNATURE_TYPE.PREPARED_BY)} />
								</Fragment>
							</div>

							<div className="work-completion-report-details__column">
								<Fragment>
									<AppSelectInput name="acknowledgedBy.employeeId" label="Acknowledged By" placeholder="Search by User ID or Name" loadOptions={getEmployeeMaintenanceListing} value={formik.values.acknowledgedBy?.employeeId} error={formik.errors.acknowledgedBy?.employeeId} touched={formik.touched.acknowledgedBy?.employeeId} onChange={formik.handleChange} />

									<AppInputDragAndDrop name="acknowledgedBy.signature" accept="image/png, image/jpeg, image/jpg" value={formik.values.acknowledgedBy?.signature} error={formik.errors.acknowledgedBy?.signature} touched={formik.touched.acknowledgedBy?.signature} onChange={(name, file) => onHandleSetSignature(name, file, SIGNATURE_TYPE.ACKNOWLEDGED_BY)} />
								</Fragment>
							</div>
						</div>

						<div className="work-completion-report-details__row work-completion-report-details__row--signature">
							<div className="work-completion-report-details__column">
								<Fragment>
									<AppSelectInput name="verifiedBy.employeeId" label="Verified By" placeholder="Search by User ID or Name" loadOptions={getEmployeeMaintenanceListing} value={formik.values.verifiedBy?.employeeId} error={formik.errors.verifiedBy?.employeeId} touched={formik.touched.verifiedBy?.employeeId} onChange={formik.handleChange} />

									<AppInputDragAndDrop name="verifiedBy.signature" accept="image/png, image/jpeg, image/jpg" value={formik.values.verifiedBy?.signature} error={formik.errors.verifiedBy?.signature} touched={formik.touched.verifiedBy?.signature} onChange={(name, file) => onHandleSetSignature(name, file, SIGNATURE_TYPE.VERIFIED_BY)} />
								</Fragment>
							</div>
						</div>
					</div>

					<div className="work-completion-report-details__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleBack} />

						<AppButton type="submit" label={submitLabel} disabled={formik.isSubmitting} />
					</div>
				</form>
			</div>

			<AppAssetConfirmationModal ref={confirmationRef} />
		</div>
	);
};
export default AppWorkCompletionReportDetails;
