import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import { useParams, useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import STATUS from "constants/status";
import ENDPOINT_PATH from "constants/end-point-path";

import AppStatus from "components/app-status";
import AppCalendarSchedule from "components/app-calendar-schedule/app-calendar-schedule";

const AppAttendanceSummaryCalendar = () => {
	const { id } = useParams();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const [data, setData] = useState(convertPaginationTableData());
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", sort: searchParams.get("sort") || "", tab: "ATTENDANCE_SUMMARY" });

	//prettier-ignore
	const onHandleGetList = useCallback(async (date) => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10, "user-id": id, attendanceRecordDate: date });

			memoSearchParams.current(params);

			response = await api.get.humanResource.attendanceSummaryEmployee(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, [id]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "clockIn",
			label: "Clock-In",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;

					if (data[rowIndex]?.status === STATUS.ON_LEAVE) return;

					return value;
				}
			}
		},
		{
			name: "clockOut",
			label: "Clock-Out",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;

					if (data[rowIndex]?.status === STATUS.ON_LEAVE) return <AppStatus status={data[rowIndex]?.status} />;

					return value;
				}
			}
		},
		{
			name: "timeWorked",
			label: "Time Worked",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;

					if (data[rowIndex]?.status === STATUS.ON_LEAVE) return;

					return value;
				}
			}
		}
	], [data]);

	useEffect(() => {
		const currentDate = dayjs(new Date()).utcOffset(0).format("YYYY-MM-DD");

		onHandleGetList(currentDate);
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.ATTENDANCE_SUMMARY_EMPLOYEE);
		};
	}, [cancelRequest]);

	return (
		<div className="app-attendance-summary-calendar">
			<div className="attendance-summary-calendar">
				<AppCalendarSchedule data={data.content} columns={tableColumns} onHandleGetList={onHandleGetList} />
			</div>
		</div>
	);
};

export default AppAttendanceSummaryCalendar;
