import api from "services/api";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

const getWorkInspectionListing = async (payload) => {
	let response = null;

	let errorMessage = null;

	try {
		const params = { page: payload?.page || 0, size: 10, label: payload?.keyword || "" };

		const res = await api.get.workInspectionReport.reports(params);

		const content = res.content.map(({ referenceNo, id, ...o }) => ({ label: referenceNo, value: id, ...o }));

		response = { ...res, content };
	} catch (error) {
		serveLayoutRequestErrors(error, (message) => {
			errorMessage = message;
		});
	}

	if (response) return response;

	throw errorMessage;
};

export default getWorkInspectionListing;
