import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ERRORS from "constants/errors";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppInputTime from "components/app-input-time";
import AppCalendarSchedule from "components/app-calendar-schedule/app-calendar-schedule";

const AppScheduledAttendanceEmployeeCalendar = () => {
	const { id } = useParams();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [data, setData] = useState([]);
	const [editDate, setEditDate] = useState([]);
	const [editingStatus, setEditingStatus] = useState(false);
	const formikRef = useRef(null);

	//prettier-ignore
	const onHandleGetList = useCallback(async (date) => {
		let response = null;

		try {
			let currentMonth = new Date().getMonth + 1 === dayjs(date.split("T")[0]).format("M");

			const payload = { employeeId: id, currentMonth: currentMonth, attendanceScheduledDate: date };

			response = await api.post.humanResource.scheduledAttendanceEmployee(payload);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			setData(response);
		}
	}, [id]);

	const generateMonthDates = (month, year) => {
		const dates = [];
		const firstDayOfMonth = new Date(year, month, 1);
		const lastDayOfMonth = new Date(year, month + 1, 0);

		for (let date = new Date(firstDayOfMonth); date <= lastDayOfMonth; date.setDate(date.getDate() + 1)) {
			dates.push({
				date: date.toLocaleString("sv", { year: "numeric", month: "2-digit", day: "2-digit" }),
				day: date.toLocaleString("sv", { day: "numeric" }),
				startTime: "",
				endTime: ""
			});
		}

		return dates;
	};

	const formik = useFormik({
		initialValues: {
			startTime: [],
			endTime: []
		},
		validationSchema: yup.object({
			startTime: yup.array().of(
				yup.mixed().test("required-when-endtime", ERRORS.REQUIRED, function (value, context) {
					const path = context.path.split("[");
					const index = path[1]?.split("]")[0];
					const endTimeArray = context.options.context?.endTime;
					const endTimeValue = endTimeArray ? endTimeArray[index] : "";

					if (!endTimeValue && !value) return true;

					return !!value;
				})
			),
			endTime: yup.array().of(
				yup.mixed().test("required-when-startTime", ERRORS.REQUIRED, function (value, context) {
					const path = context.path.split("[");
					const index = path[1]?.split("]")[0];
					const startTimeArray = context.options.context?.startTime;
					const startTimeValue = startTimeArray ? startTimeArray[index] : "";

					if (!startTimeValue && !value) return true;

					return !!value;
				})
			)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const editableDate = useCallback((month, year) => {
		const dates = generateMonthDates(month, year);
		let startTime = [];
		let endTime = [];
		
		setEditDate({ month, year })
		
		dates.forEach((dateObj) => {
			const matchDate = data.find((d) => d.clockInDate === dateObj.date);

			startTime.push(matchDate ? matchDate.startTime : "");

			endTime.push(matchDate ? matchDate.endTime : "");
		});

		formik.setValues({ startTime: startTime, endTime: endTime });

		formikRef.current.onHandleShow({ startTime: startTime, endTime: endTime });
		
		setEditingStatus(!editingStatus);
	}, [data, editingStatus, formik]);

	//prettier-ignore
	const onHandleConfirmEdit = useCallback(async (values) => {
		let updatedData = data.map((item) => {
			const itemDate = dayjs(item.clockInDate);
			const day = itemDate.date();
			const year = itemDate.year();
			const month = itemDate.month();
			const index = values.startTime.findIndex((_, i) => i + 1 === day);

			if (index !== -1 && month === editDate.month && year === editDate.year) {
				return {
					...item,
					clockIn: values.startTime[index] || item.clockInDate,
					clockOut: values.endTime[index] || item.clockOutDate
				};
			}

			return item;
		});

		values.startTime.forEach((time, index) => {
			const day = index + 1;
			const isDateInData = data.some((item) => {
				const itemDate = dayjs(item.clockInDate);

				return itemDate.date() === day && itemDate.month() === editDate.month && itemDate.year() === editDate.year;
			});

			if (!isDateInData && time) {
				const newDataDate = dayjs().year(editDate.year).month(editDate.month).date(day).format(DATE_TIME.YYYY_MM_DD);

				updatedData.push({
					date: newDataDate,
					clockIn: time,
					clockOut: values.endTime[index]
				});
			}
		});

		let response = null;

		try {
			await api.post.humanResource.scheduledAttendanceEmployee(updatedData);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			setData(updatedData);

			setEditingStatus(false);
		}
	}, [data, editDate]);

	//prettier-ignore
	const onHandleChange = useCallback(async (name, value) => {
		await formik.setFieldValue(name, value);

		formikRef.current.onHandleFormik(name, value);
	}, [formik]);

	//prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		formikRef.current.onHandleShow(values);

		onHandleConfirmEdit(values);
	}, [onHandleConfirmEdit]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "startTime",
			label: "Start Time",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowData = tableMeta.rowData;
					const index = tableMeta.rowIndex;

					if (!editingStatus) {
						if (rowData[1]) return dayjs(value).format(DATE_TIME.HH_MM);

						return;
					}

					return <AppInputTime required name={`startTime[${index}]`} label="" placeholder="" value={formik.values.startTime?.[index]} error={formik.errors.startTime?.[index]} touched={formik.touched.startTime?.[index]} onChange={onHandleChange} />;
				}
			}
		},
		{
			name: "endTime",
			label: "End Time",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowData = tableMeta.rowData;
					const index = tableMeta.rowIndex;

					if (!editingStatus) {
						if (rowData[2]) return dayjs(value).format(DATE_TIME.HH_MM);

						return;
					}

					return <AppInputTime required name={`endTime[${index}]`} label="" placeholder="" value={formik.values.endTime?.[index]} error={formik.errors.endTime?.[index]} touched={formik.touched.endTime?.[index]} onChange={onHandleChange} />;
				}
			}
		}
	], [editingStatus, formik, onHandleChange]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		customFooter: () => null
	}), []);

	useEffect(() => {
		const currentDate = dayjs(new Date()).utcOffset(0).format("YYYY-MM-DDTHH:mm:ss.sss[Z]");

		onHandleGetList(currentDate);
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.SCHEDULED_ATTENDANCE_EMPLOYEE);
		};
	}, [cancelRequest]);

	return (
		<div className="app-scheduled-attendance-employee-calendar">
			<div className="scheduled-attendance-employee-calendar">
				<form onSubmit={formik.handleSubmit}>
					<AppCalendarSchedule ref={formikRef} formik={{ ...formik }} data={data} columns={tableColumns} options={tableOptions} editableDate={editableDate} onHandleGetList={onHandleGetList} />
				</form>
			</div>
		</div>
	);
};

export default AppScheduledAttendanceEmployeeCalendar;
